import axios from 'axios';
import apiHelperService from "./api.helper.service";

class apiService {
  private host: string;
  // private productHost: string;
  constructor() {
    this.host = process.env.REACT_APP_FIDELITY_API_URL;
    // this.host = 'https://localhost:44379';
    // this.productHost = process.env.REACT_APP_ECOMMERCE_API_URL;
  }

  getMe = () => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/User/GetMyProfile`);
  };

  getTagsBySpaceId = (spaceId: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/Tag/GetTagsBySpaceId?SpaceId=${spaceId}`);
  }

  createTag = (
    spaceId: string,
    label: string,
    description: string,
    anchorPosition: string,
    stemVector: string,
    mediaType: string,
    mediaSource: string,
    productSKU: string,
    productBrand: string,
    productPrice: string,
    productName: string,
    productImageUrl: string,
    productUrl: string
  ) => {
    return apiHelperService.postCallAPIWrapper(this.host + `/api/v1/Tag/CreateTag`,
      {
        spaceId: spaceId,
        label: label,
        description: description,
        anchorPosition: anchorPosition,
        stemVector: stemVector,
        mediaType: mediaType,
        mediaSource: mediaSource,
        productSKU: productSKU,
        productBrand: productBrand,
        productPrice: productPrice,
        productName: productName,
        productImageUrl: productImageUrl,
        productUrl: productUrl
      }
    )
  }

  deleteTag = (label: string, anchorPosition: string) => {
    return apiHelperService.deleteCallAPIWrapper(this.host + `/api/v1/Tag/DeleteTag?label=${label}&AnchorPosition=${anchorPosition}`);
  }

  // updateTagLabel = (label: string, anchorPosition: string, newLabel: string) => {
  //   return apiHelperService.postCallAPIWrapper(this.host + `/api/v1/Tag/UpdateTagLabel`, { label: label, anchorPosition: anchorPosition, newLabel: newLabel })
  // }

  updateProductTag = (
    label: string,
    anchorPosition: string,
    newLabel: string,
    productSKU: string,
    productBrand: string,
    productPrice: string,
    productName: string,
    productImageUrl: string,
    productUrl: string
  ) => {
    return apiHelperService.postCallAPIWrapper(this.host + `/api/v1/Tag/UpdateProductTag`,
      {
        label: label,
        anchorPosition: anchorPosition,
        newLabel: newLabel,
        productSKU: productSKU,
        productBrand: productBrand,
        productPrice: productPrice,
        productName: productName,
        productImageUrl: productImageUrl,
        productUrl: productUrl
      })
  }

  updateTagPosition = (label: string, anchorPosition: string, newPosition: string, newStemVector: string) => {
    return apiHelperService.postCallAPIWrapper(this.host + `/api/v1/Tag/UpdateTagPosition`, { label: label, anchorPosition: anchorPosition, newPosition: newPosition, newStemVector: newStemVector })
  }

  getProductPreviewByProsuctSKU = (productSKU: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/Tag/GetProductPreviewByProsuctSKU?ProductSKU=${productSKU}`);
  }

  getTagByLabelAndPosition = (label: string, anchorPosition: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/Tag/GetTagByLabelAndPosition?Label=${label}&AnchorPosition=${anchorPosition}`);
  }

  forgotPassword(email: string) {
    axios
      .post(this.host + `/api/v1/user/command/forgot-password-restore`, { email: email })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  resetPassword(password: string, email: string, code: string) {
    axios
      .post(this.host + `/api/v1/user/command/restore-password-by-code`, {
        code: code,
        email: email,
        newPassword: password,
      })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  confirmEmail(email: string, code: string) {
    return axios
      .patch(this.host + `/api/v1/user/command/confirm-email`, { Email: email, Code: code })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  signUp(companyName: string, username: string, firstname: string, lastname: string, password: string) {
    return axios
      .post(this.host + `/api/v1/tenant/command/sign-up`, {
        CompanyName: companyName,
        Username: username,
        Firstname: firstname,
        Lastname: lastname,
        Password: password
      })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  changePassword = (oldPassword: string, password: string, confirmPassword: string) => {
    return apiHelperService.patchCallAPIWrapper(this.host + `/api/v1/user/command/change-password`, { OldPassword: oldPassword, NewPassword: password, ConfirmPassword: confirmPassword })
  }

  getTenants = (page: number, rowsPerPage: number, order: string, orderBy: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/account/query/accounts`, { params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage } });
  }

  getTenant = (id: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/account/query/account/${id}`);
  }


  getUsers = (page: number, rowsPerPage: number, order: string, orderBy: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/User/query/Users`, {
      params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage }
    });
  }

  getUser = (id: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/user/query/user/${id}`);
  }

  getCategories = (page: number, rowsPerPage: number, order: string, orderBy: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/category/query/categories`, { params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage } });
  }

  getCategoriesForTenant = (id: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/category/query/categories/${id}`);
  }

  getCategory = (id: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/category/query/category/${id}`);
  }

  getProducts = (page: number, rowsPerPage: number, order: string, orderBy: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/product/query/products`, { params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage } });
  }

  getProduct = (id: string) => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/product/query/product/${id}`);
  }

  getDashboard = () => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/dashboard/query/dashboard`);
  }

  getUserProfile = () => {
    return apiHelperService.getCallAPIWrapper(this.host + `/api/v1/user/query/user-profile`);
  }

  postUserProfile = (formData: any, params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/user/command/update-profile", formData, params)
  }

  createUser = (formData: any, params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/user/command/create", formData, params)
  }

  updateUser = (formData: any, params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/user/command/update", formData, params)
  }

  deleteUser = (id: any) => {
    return apiHelperService.deleteCallAPIWrapper(this.host + "/api/v1/user/command/delete/" + id);
  }

  setMessageAsRead = (messages: any) => {
    return apiHelperService.patchCallAPIWrapper(this.host + `/api/v1/message/command/set-as-read`, { messageIds: messages })
  }

  createAccount = (formData: any, params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/account/command/create", formData, params)
  }

  updateAccount = (formData: any, params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/account/command/update", formData, params)
  }

  deleteAccount = (id: any) => {
    return apiHelperService.deleteCallAPIWrapper(this.host + "/api/v1/account/command/delete/" + id);
  }

  createCategory = (params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/category/command/create", params)
  }

  updateCategory = (params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/category/command/update", params)
  }

  deleteCategory = (id: any) => {
    return apiHelperService.deleteCallAPIWrapper(this.host + "/api/v1/category/command/delete/" + id);
  }

  createProduct = (params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/product/command/create", params)
  }

  updateProduct = (params: any) => {
    return apiHelperService.postCallAPIWrapper(this.host + "/api/v1/product/command/update", params)
  }

  deleteProduct = (id: any) => {
    return apiHelperService.deleteCallAPIWrapper(this.host + "/api/v1/product/command/delete/" + id);
  }
}

export default new apiService();
