import React, { useState, useContext, useRef } from "react";
import { Grid, TextField, Button, CssBaseline, Snackbar } from "@material-ui/core";
import authService from "../services/auth.service";
import { UserContext } from "../contexts/UserContext";
import LoadingBar from 'react-top-loading-bar';
import { makeStyles } from "@material-ui/core/styles";
import apiService from "../services/api.service";
import { lightTheme } from "../themes/lightTheme"
import { ThemeProvider } from "@material-ui/styles";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayErrorText, setDisplayErrorText] = useState("");
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [alertErrorMessage,] = useState("");
  const navigate = useNavigate();
  const [, setContext] = useContext(UserContext);
  const inputRef = useRef();
  const defaultErrorText = "The username either doesn't exists or the password is wrong";
  const progressRef = useRef(null);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      height: "calc(100vh)",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
      position: "relative",
      backgroundColor: '#000',
      background: `linear-gradient( 90deg, #7e6efc, #dc1df6 )`,
    },
    rootContainer: {
      maxWidth: "450px",
      position: "relative",
      backgroundColor: '#fff',
      width: '100%',
      paddingBottom: 24,
      borderRadius: 8
    },
    title: {
      fontStyle: "italic",
    },
    googleIcon: {
      backgroundColor: "#FD397A",
      color: "#ffffff",
      margin: theme.spacing(1),
    },
    facebookIcon: {
      backgroundColor: "#5867DD",
      color: "#ffffff",
      margin: theme.spacing(1),
    },
    twitterIcon: {
      backgroundColor: "#5578EB",
      color: "#ffffff",
      margin: theme.spacing(1),
    },
    logoContainer: {
      background: '#000',
      color: '#fff',
      marginBottom: 76,
      borderRadius: '8px 8px 0 0',
      boxShadow: `rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px`,
    },
    logoImg: {
      display: 'block',
      margin: 8,
      height: 120,
    }
  }));

  const classes = useStyles();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    progressRef.current.continuousStart();
    try {
      await authService.login(email, password);
      const result = await apiService.getMe();
      console.log('result !!!', result);
      if (result) {
        setContext(result.data);
        setTimeout(() => {
          navigate("/");
        }, (350));
        progressRef.current.complete();
      }
    } catch (error) {
      console.log('error !!!', error.response);
      if (progressRef && progressRef.current)
        progressRef.current.complete();
      setDisplayErrorText(defaultErrorText);
      if (error.response && error.response.data.errors[0].includes("confirmed")) {
        setDisplayErrorText(error.response.data.errors[0]);
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errorAlertOpen}
          onClose={() => {
            setErrorAlertOpen(false);
          }}
        >
          <Alert
            variant="outlined"
            onClose={() => {
              setErrorAlertOpen(false);
            }}
            severity="error"
          >
            {alertErrorMessage}
          </Alert>
        </Snackbar>
        <div className={classes.root}>
          <LoadingBar color="#f11946" ref={progressRef} height={3} />
          <div className={classes.rootContainer}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={8}
                // xs={12}
                alignItems="center"
                style={{ margin: 0, width: '100%' }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                  className={classes.logoContainer}
                >
                  <img src="../../assets/images/FIDELITY-FINAL.png" className={classes.logoImg} />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                >
                  <Grid item xs={12}>
                    <TextField
                      label="Username"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      ref={inputRef}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                >
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!(displayErrorText === "")}
                      helperText={
                        displayErrorText === ""
                          ? ""
                          : displayErrorText
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item style={{ marginTop: 24 }}>
                    <Button
                      style={{ textTransform: "none" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Login;
