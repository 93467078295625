import React, { useEffect, useState, useRef } from 'react';
import { Slider, Fade, makeStyles } from '@material-ui/core';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { useOnClickOutside } from '../hooks/useOnClickOutside'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 72,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
        zIndex: 5,
    },
    iconVolume: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        borderRadius: '50%',
        padding: 0,
        border: 'none',
        color: '#fff',
        boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
    },
    slider: {
        color: '#f8f9fa',
        marginBottom: 12,
    },
    thumb: {
        boxShadow: 'none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important;',
        '&:after': {
            display: 'none',
        }
    },
    backdrop: {
        background: 'transparent',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
    }
}));

type Props = {
    volume: number;
    handleVolumeChange: (newValue: number) => void;
}

const VolumeSlider: React.FC<Props> = (props: Props) => {
    const { volume, handleVolumeChange } = props;
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const [mute, setMute] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        handleVolumeChange(newValue)
    };

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    useOnClickOutside(ref, () => setOpen(false));

    useEffect(() => {
        // console.log('Mute', mute, volume);
        if (mute) {
            handleVolumeChange(0)
        } else {
            handleVolumeChange(volume)
        }
    }, [mute])

    useEffect(() => {
        if (volume === 0) { setMute(true) }
        else { setMute(false) }
    }, [volume])

    return (
        <>
            <div className={classes.root} ref={ref}>
                <Fade in={open} >
                    <Slider
                        value={volume}
                        orientation="vertical"
                        onChange={handleChange}
                        aria-labelledby="volume-slider"
                        min={0}
                        step={0.1}
                        max={1}
                        scale={(x) => x - 0.1}
                        classes={{ root: classes.slider, thumb: classes.thumb }}
                    />
                </Fade>
                {!mute ? <VolumeUp classes={{ root: classes.iconVolume }} onClick={handleClick} /> : <VolumeOffIcon classes={{ root: classes.iconVolume }} onClick={handleClick} />}
            </div>
            {open && <div className={classes.backdrop}></div>}
        </>
    );
}
export default VolumeSlider;