import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import ProductCanvas from './ProductCanvas';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'absolute',
        bottom: 16,
        left: 20,
        zIndex: 100,
        display: 'flex',
        alignItems: 'flex-end'
    },
    dialogPaper: {
        display: 'flex',
        alignItems: 'center',
        background: '#ffffff',
        minWidth: 350,
    },
    backDrop: {
        position: 'absolute',
        backdropFilter: "blur(6px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
    titleText: {
        fontFamily: 'CircularBook, sans-serif',
        color: '#000',
    },
    descriptionText: {
        fontFamily: 'CircularLight, sans-serif',
        color: '#333335',
    },
    buttonRoot: {
        marginTop: theme.spacing(2),
        fontFamily: 'CircularBook, sans-serif',
        letterSpacing: 2,
        color: '#ffffff',
        background: '#ee1f3b',
        fontWeight: 'bold',
        '&:hover': {
            color: '#ffffff',
            background: '#ee1f3bc9',
        }
    },
    modelContainer: {
        height: 250,
    }
}));

type RewardModalProps = {
    open: boolean;
    onClose: () => void;
}

const RewardModal: React.FC<RewardModalProps> = (props) => {
    const { open, onClose } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            container={() => document.getElementById('parent-main')}
            classes={{ paper: classes.dialogPaper }}
            disableEscapeKeyDown={true}
            BackdropProps={{
                classes: {
                    root: classes.backDrop,
                },
            }}
            style={{ position: 'absolute' }}
        >
            <DialogTitle style={{ paddingBottom: 0 }}><img src='../../assets/images/brand-cotton-on.svg' width={120} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <Typography variant="h5" color='textPrimary' gutterBottom className={classes.titleText}>
                    Congratulations!
                </Typography>
                <Typography variant="body1" color='textPrimary' gutterBottom className={classes.descriptionText}>
                    Here's the 10% discount.
                </Typography>
                <Box className={classes.modelContainer}>
                    <ProductCanvas />
                </Box>
                <Button
                    variant='contained'
                    onClick={onClose}
                    classes={{ root: classes.buttonRoot }}
                >
                    Collect
                </Button>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}

export default RewardModal