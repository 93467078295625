import React, { useEffect, useRef, useContext } from 'react'
// import clsx from "clsx";
import AdminDrawer from "../layouts/AdminDrawer";
// import { useHistory } from 'react-router'
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme } from "../themes/darkTheme"
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../themes/lightTheme';
// import apiService from '../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from "../contexts/UserContext";
// import moment from 'moment';
import { ReloadContext } from "../contexts/ReloadContext";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: 'relative',
    flexGrow: 1,
    height: "calc(100vh - 65px)",
    overflowY: "auto",
    marginTop: "65px",
  },
  container: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    boxShadow: "none",
    position: 'relative',
  },

  media: {
    background: "rgba(39,62,84,0.82)",
    opacity: 0.7,
  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
  }
}));

// const chartDataConvert = (items) => {
//   let result = [];
//   items.forEach(element => {
//     let time = moment(element.dateTime).format('L');
//     let amount = element.count;
//     result.push({ time, amount });
//   });
//   return result;
// };

function Dashboard() {
  // const history = useHistory();
  const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const progressRef = useRef(null);
  // const [categoryData, setCategoryData] = useState([]);
  // const [userData, setUserData] = useState([]);
  // const [productData, setProductData] = useState([]);
  // const [accountData, setAccountData] = useState([]);
  const [userContext,] = useContext(UserContext);
  const [reloadContext,] = useContext(ReloadContext);

  useEffect(() => {
    progressRef.current.complete();
  }, [reloadContext]);

  return (
    <>
      <ThemeProvider theme={userContext && userContext.themeColor === 1 ? lightThemeAdmin : darkTheme}>
        <CssBaseline />
        <div className={classes.root}>
          <LoadingBar color="#f11946" ref={progressRef} height={3} />
          <AdminDrawer />
          <main className={classes.content}>
            {/* <div className={classes.appBarSpacer} /> */}
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
              </Grid>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Dashboard;