import { SceneComponent } from '../SceneComponent';
import { Mesh, MeshBasicMaterial, AnimationMixer, AnimationAction, Object3D } from 'three';

interface Inputs {
  loadingState: string | boolean;
  period: number;
  size: { x: number; y: number; z: number; };
  transitionInDuration: number;
  color: number;
  model: Object3D | null;
  state: boolean;
}

class ModelSpinning extends SceneComponent {
  private mixer: AnimationMixer | null = null;
  private box: Mesh | null;

  inputs: Inputs = {
    loadingState: 'Idle',
    period: 4,
    size: { x: 1, y: 1, z: 1 },
    transitionInDuration: 0.2,
    color: 0x00aa00,
    model: null,
    state: true,
  }

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({
      color: this.inputs.color,
      transparent: true,
      opacity: 0.2,
    });
    this.box = new THREE.Mesh(geometry, material);
    this.mixer = new THREE.AnimationMixer(this.box);

    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.gltfLoader') {
        this.bind('loadingState', component, 'loadingState');
      }
    }

    this.onInputsUpdated();
  }

  onDestroy() {
    const material = this.box.material as MeshBasicMaterial;
    this.box.geometry.dispose();
    material.dispose();

    this.mixer = null;
  }

  onInputsUpdated() {
    const THREE = this.context.three;
    if (!this.inputs.model) {
      return;
    }

    // console.log(this.inputs.model);
    this.mixer = new THREE.AnimationMixer(this.inputs.model);

    if (this.inputs.state) {
      this.inputs.loadingState = 'Loaded';
    } else {
      this.inputs.loadingState = 'Stop';
    }

    switch (this.inputs.loadingState) {
      case 'Idle':
        this.inputs.model.visible = false;
        break;
      case 'Loading':
        this.inputs.model.visible = false;
        break;
      case 'Loaded':
        {
          this.inputs.model.visible = true;

          const yAxis = new THREE.Vector3(0, 1, 0);
          const frame0 = new THREE.Quaternion().setFromAxisAngle(yAxis, 0);
          const frame1 = new THREE.Quaternion().setFromAxisAngle(yAxis, Math.PI);
          const frame2 = new THREE.Quaternion().setFromAxisAngle(yAxis, Math.PI * 2);
          const track = new THREE.QuaternionKeyframeTrack('.quaternion', [
            0, this.inputs.period * 0.5, this.inputs.period
          ], [
            frame0.x, frame0.y, frame0.z, frame0.w,
            frame1.x, frame1.y, frame1.z, frame1.w,
            frame2.x, frame2.y, frame2.z, frame2.w
          ]);

          const clip = new THREE.AnimationClip(null, this.inputs.period, [track]);
          const action: AnimationAction = this.mixer.clipAction(clip, this.inputs.model);
          action.play();

          const onEnterTime = this.inputs.transitionInDuration;
          // const opacityTrack = new THREE.NumberKeyframeTrack('.material.opacity', [0, onEnterTime], [0, 0.7], THREE.InterpolateSmooth);
          const scaleTrack = new THREE.VectorKeyframeTrack('.scale', [0, onEnterTime],
            [0.5, 0.5, 0.5, this.inputs.size.x, this.inputs.size.y, this.inputs.size.z], THREE.InterpolateSmooth);
          const onEnterClip = new THREE.AnimationClip(null, onEnterTime, [scaleTrack]);
          const onEnterAction: AnimationAction = this.mixer.clipAction(onEnterClip, this.inputs.model);
          onEnterAction.loop = THREE.LoopOnce;
          onEnterAction.clampWhenFinished = true;
          onEnterAction.play();
        }
        break;
      case 'Stop':
        {
          this.inputs.model.visible = true;
          const onEnterTime = this.inputs.transitionInDuration;
          const scaleTrack = new THREE.VectorKeyframeTrack('.scale', [0, onEnterTime],
            [this.inputs.size.x, this.inputs.size.y, this.inputs.size.z, 0.5, 0.5, 0.5], THREE.InterpolateSmooth);
          const onEnterClip = new THREE.AnimationClip(null, onEnterTime, [scaleTrack]);
          const onEnterAction: AnimationAction = this.mixer.clipAction(onEnterClip, this.inputs.model);
          onEnterAction.loop = THREE.LoopOnce;
          onEnterAction.clampWhenFinished = true;
          onEnterAction.play();
        }
        break;
      case 'Error':
        console.warn(`Loading indicator transitioned to error state.`);
        break;
    }
  }

  onTick(delta: number) {
    if (this.mixer) {
      this.mixer.update(delta / 2000);
    }
  }
}

export const modelSpinningType = 'mp.modelSpinning';
export const makeModelSpinning = function () {
  return new ModelSpinning();
}
