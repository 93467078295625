import { withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

const PlayToggleButton = withStyles((theme: any) => ({
  root: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    padding: 0,
    border: 'none',
    color: '#fff',
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
  },
  selected: {
    color: '#fff !important',
    backgroundColor: 'transparent !important',
  },
}))((props: any) => (
  <ToggleButton {...props} value="check" selected={props.selected} onChange={props.onChange}>
    {props.selected ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
    {/* {props.selected ? <img src="images/247hr.png" alt="247icon" width={25} /> : <PlayCircleOutlineIcon />} */}
  </ToggleButton>
));

export default PlayToggleButton;
