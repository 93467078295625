import { LinearProgress, withStyles, LinearProgressProps } from '@material-ui/core';
import React from 'react';

// type Props = {
//   value: number;
//   variant: string;
// }
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: 250,
    marginTop: theme.spacing(1),
  },
  colorPrimary: {
    backgroundColor: '#a5b1c2',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#a34ffa',
  },
}))(({ value, variant }: LinearProgressProps) => (
  <LinearProgress variant={variant} value={value} />
));

export default BorderLinearProgress;
