import { Avatar, Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        bottom: 0,
        right: 10,
        zIndex: 100,
        display: 'flex',
    },
    iconButton: {
        backgroundColor: 'transparent',
        // borderRadius: '50%',
        textShadow: '1px 1px #000',
        padding: 0,
        border: 'none',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        verticalAlign: 'baseline',
        minWidth: 'unset',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    logo: {
        width: 12,
        height: 12,
        marginLeft: 4,
        marginRight: 4,
    },
    normalText: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        color: '#fff',
        fontSize: '0.65rem',
        textShadow: '1px 1px #000',
    }
}));

const FidelityIconLink: React.FC = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box component='span' className={classes.normalText}>powered by </Box>
            <Button href="https://www.fidelity-apps.com/" target="_blank" rel="noreferrer" disableRipple className={classes.iconButton}>
                <Avatar alt="Remy Sharp" src="../../assets/icon-fidelity.png" classes={{ root: classes.logo }} />
                Fidelity
            </Button>
        </Box>
    );
}

export default FidelityIconLink;