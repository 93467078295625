import { Backdrop, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { Mattertag } from '@mp/bundle-sdk/sdk';
import React from 'react';
import ReactPlayer from 'react-player';
import ModalBodyImage from './ModalBodyImage';
// import ModalBodyImageHorizontal from './ModalBodyImageHorizontal';
// import ModalBodyImageVertical from './ModalBodyImageVertical';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    backDrop: {
        position: 'absolute',
    }
}));

interface Props {
    open: boolean;
    close: () => void;
    tagData?: Mattertag.MattertagData;
}

const ModalContainer: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { open, close, tagData } = props;

    const getModalBody = (type: string) => {
        switch (type) {
            case 'mattertag.media.video':
                return <ReactPlayer url={tagData.media.src} playing={true} width="960px" height="540px" loop style={{ outline: 'none' }} />;
            case 'mattertag.media.photo':
                return <ModalBodyImage label={tagData.label} anchorPosition={tagData.anchorPosition} close={close} />
            default:
                return <></>;
        }
    }

    return (
        <Modal
            open={open}
            onClose={close}
            container={() => document.getElementById('parent-main')}
            className={classes.root}
            disableAutoFocus
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes: {
                    root: classes.backDrop,
                },
            }}
            style={{ position: 'absolute' }}
        >
            <Fade in={open}>
                <>
                    {tagData && getModalBody(tagData.media.type)}
                </>
            </Fade>
        </Modal>
    );
}

export default ModalContainer;