import React from 'react'
import { Main } from './Main'
import { Button, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

// import { AppState, items, slots } from '../AppState';

// const appState = new AppState();
// appState.items = items;
// appState.slots = slots;

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'absolute',
        top: 10,
        left: 20,
        zIndex: 20,
        textTransform: "unset",
        background: "#4167b2",
        marginLeft: 12,
        color: theme.palette.getContrastText("#4167b2"),
        "&:hover": {
            background: "#2d487c",
            color: theme.palette.getContrastText("#2d487c"),
        },
        display: 'none',
    },
}));

const PreviewSpace = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Button variant="contained" className={classes.button} onClick={() => navigate(-1)} >Back</Button>
            <Main spaceId='' />
        </div>
    )
}

export default PreviewSpace;