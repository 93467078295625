import React, { useEffect, useState, useRef, useContext } from "react";
import LoadingBar from "react-top-loading-bar";
import { ReloadContext } from "../../contexts/ReloadContext";
import EntityListFrame from "../../layouts/EntityListFrame";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  makeStyles,
  Modal,
  Typography
} from "@material-ui/core";
import { Main } from '../../components/Main';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// import { Link } from "react-router-dom";
// import { AppState, items, slots } from '../../AppState';

// const appState = new AppState();
// appState.items = items;
// appState.slots = slots;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: 12,
  },
  button: {
    textTransform: "unset",
    background: "#4167b2",
    marginLeft: 12,
    color: theme.palette.getContrastText("#4167b2"),
    "&:hover": {
      background: "#2d487c",
      color: theme.palette.getContrastText("#2d487c"),
    }
  },
  spaceContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  cardContainer: {
    // background: 'red',
  },
  media: {
    height: 200,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4)
  },
  exitButton: {
    position: 'absolute',
    zIndex: 100,
    top: 10,
    left: 10,
    textTransform: 'unset',
    backgroundColor: '#ee1f3b',
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ad172b',
    },
  },
  manageButton: {
    backgroundColor: '#ee1f3b',
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ad172b',
    },

  }
}));

function ManageStore() {
  const [reloadContext,] = useContext(ReloadContext);
  const classes = useStyles();
  const progressRef = useRef(null);
  const [openManageModal, setOpenManageModal] = useState<boolean>(false);

  useEffect(() => {
    progressRef.current.complete();
  }, [reloadContext]);

  const handleManageModalOpen = () => {
    setOpenManageModal(true);
  }

  const handleManageModalClose = () => {
    setOpenManageModal(false);
  }


  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height={3} />
      <EntityListFrame>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            color="inherit"
            noWrap
          >
            {`Manage Store`}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ position: 'relative', width: '100%', height: '100%' }}>
          {/* <Grid item container className={classes.spaceContainer}>
            <Main appState={appState} />
          </Grid> */}
          <Grid item container spacing={3} className={classes.cardContainer}>
            <Grid item xs={12} md={3}>
              <Card>
                <CardMedia
                  className={classes.media}
                  image="../../../assets/images/Cotton-On-New-Point.jpg"
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h5">
                    Cotton-On New Store 1
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" variant="contained" onClick={handleManageModalOpen} className={classes.manageButton}>
                    Manage
                  </Button>
                  {/* <Button size="small" color="primary">
                    Learn More
                  </Button> */}
                </CardActions>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Card>
                <CardMedia
                  className={classes.media}
                  image="../../../assets/images/Cotton-On-New-Point.jpg"
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h5">
                    Cotton-On New Store 2
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" variant="contained">
                    Manage
                  </Button>
                </CardActions>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
        <Modal
          open={openManageModal}
          onClose={handleManageModalClose}
          className={classes.modal}
        >
          <Box className={classes.spaceContainer}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleManageModalClose}
              className={classes.exitButton}
              startIcon={<ArrowBackIosIcon />}
            >
              Exit
            </Button>
            <Main spaceId={''} />
          </Box>
        </Modal>
      </EntityListFrame>
    </>
  );
}

export default ManageStore;
