import React, { useEffect, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import LoadingBar from "react-top-loading-bar";
import { ReloadContext } from "../../contexts/ReloadContext";
import EntityListFrame from "../../layouts/EntityListFrame";

function Floorplan() {
  const [reloadContext,] = useContext(ReloadContext);

  const progressRef = useRef(null);

  useEffect(() => {
    progressRef.current.continuousStart();
  }, [reloadContext]);


  return (
    <>
      <LoadingBar color="#f11946" ref={progressRef} height={3} />
      <EntityListFrame>
        {/* <Grid item xs={12}>
          <Typography
            variant="h4"
            color="inherit"
            noWrap
          >
            {`Floorplan`}
          </Typography>
        </Grid> */}
        <Grid item container justifyContent="center" xs={12} style={{ background: '#33363b', width: '100%', height: '100%' }}>
          <img src="./assets/images/colorplan_000.jpg" style={{ display: 'block', width: 'auto', height: '100%' }} onLoad={() => progressRef.current.complete()} />
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default Floorplan;
