import React, { useContext, useEffect, useState } from "react";
import authService from "../services/auth.service";
import { UserContext } from "../contexts/UserContext";
import { AdminDrawerContext } from "../contexts/AdminDrawerContext";
import { ReloadContext } from "../contexts/ReloadContext";
import apiService from "../services/api.service";
import clsx from "clsx";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
  Avatar, CssBaseline, Menu, MenuItem, Tooltip,
  makeStyles, Drawer, AppBar, Toolbar, List, ListItem,
  ListItemIcon, ListItemText, IconButton, Typography, Divider
} from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Refresh from "@material-ui/icons/Refresh";
import { useMediaLayout } from "use-media";
import NotificationPopup from "../parts/NotificationPopup";
import useComponentVisible from "../hooks/useComponentVisible";
import { Link } from "react-router-dom";

import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import StoreIcon from '@material-ui/icons/Store';
import FloorplanIcon from '@material-ui/icons/PictureInPictureAlt';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerButtons: {
    color: theme.palette.adminDrawer.button,
  },
  logo: {
    maxWidth: 35,
    maxHeight: 35,
  },
  minWidth: {
    minWidth: "35px",
  },
  avatar: {
    height: "35px",
    width: "35px",
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  toolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    backgroundColor: theme.palette.adminDrawer.main,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.adminDrawer.drawer,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.adminDrawer.button,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    paddingLeft: "10px",
    flexGrow: 1,
    color: theme.palette.adminDrawer.text,
  },
  drawerPaper: {
    overflowX: 'hidden',
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: theme.palette.adminDrawer.drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  customBadge: {
    backgroundColor: "#BB86FC",
    color: "white",
  },
  text: {
    color: theme.palette.adminDrawer.text,
  },
  'message-text > a': {
    color: theme.palette.adminDrawer.text
  },
  divider: {
    background: theme.palette.adminDrawer.divider,
  },
  bottomMenu: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

export default function AdminDrawer() {
  const classes = useStyles();
  const [profileUrl, setProfileUrl] = useState("");
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const openProfile = Boolean(profileAnchorEl);
  const [userContext, setUserContext] = useContext(UserContext);
  const [adminDrawerContext, setAdminDrawerContext] = useContext(
    AdminDrawerContext
  );
  const [, setReloadContext] = useContext(ReloadContext);
  const [notificationBadge, setNotificationBadge] = useState();

  // const [openNotificationPopup, setOpenNotificationPopup] = useState(false);

  const isWide = useMediaLayout({ maxWidth: "1400px" });
  const navigate = useNavigate();
  const {
    ref1,
    // ref2,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userContext === undefined || userContext.id === undefined) {
          const result = await apiService.getMe();
          setUserContext(result.data);
        }
      } catch {
        navigate("/login");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (notificationBadge && !isComponentVisible) {
      var messages = [];
      var messagesToCleanUp = userContext.messages.filter(
        (x: any) => x.isRead === false
      );

      for (let mess of messagesToCleanUp) messages.push(mess.id);

      if (messages.length > 0) {
        apiService.setMessageAsRead(messages).then(() => {
          apiService.getMe().then((result: any) => {
            setUserContext(result);
          });
        });
      }
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isWide) {
      setAdminDrawerContext(false);
    }
  }, [isWide]);

  useEffect(() => {
    // console.log('====================================');
    // console.log("userContext", userContext);
    // console.log('====================================');

    setProfileUrl(
      userContext && userContext.avatarExtension
        ? "/dynamic/user/images/" +
        userContext.id +
        "." +
        userContext.avatarExtension +
        "?v" +
        moment().format("YYYYMMDDhhmm")
        : ""
    );
    setNotificationBadge(
      userContext && userContext.messages && userContext.messages.filter((x: any) => x.isRead === false).length
    );
  }, [userContext]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    authService.logout();
    setUserContext(null);
    navigate("/login");
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setAdminDrawerContext(true);
  };
  const handleDrawerClose = () => {
    setAdminDrawerContext(false);
  };

  // function stringToHslColor(str: string) {
  //   let hash = 0;
  //   for (let i = 0; i < str.length; i++) {
  //     hash = str.charCodeAt(i) + ((hash << 5) - hash);
  //   }

  //   let h = hash % 360;
  //   return 'hsl(' + h + ', 55%, 50%)';
  // }

  return (
    <div className={classes.root}>
      <div
        ref={ref1}
        style={{
          position: "absolute",
          top: "42px",
          right: "85px",
          zIndex: 1500,
        }}
      >
        {isComponentVisible && (
          <p onClick={() => setIsComponentVisible(true)}>
            <NotificationPopup messages={userContext.messages} />
          </p>
        )}
      </div>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          adminDrawerContext && classes.appBarShift
        )}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              adminDrawerContext && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {/* {userContext && userContext.firstName ? userContext.firstName : userContext.email} */}
            {`COTTON ON STORE #1`}
          </Typography>
          <Tooltip title="Refresh">
            <IconButton
              color="inherit"
              className={classes.drawerButtons}
              onClick={() => setReloadContext(new Date())}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Notifications" ref={ref2}>
            <IconButton
              color="inherit"
              className={classes.drawerButtons}
              onClick={() => {
                isComponentVisible
                  ? setIsComponentVisible(false)
                  : setIsComponentVisible(true);
              }}
            >
              <Badge
                badgeContent={notificationBadge}
                classes={{ badge: classes.customBadge }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip> */}
          <div>
            <IconButton onClick={handleMenu} color="inherit" >
              {userContext && userContext.email ? (
                <Avatar src={profileUrl ? profileUrl : "./assets/images/avatar.png"} className={classes.avatar}
                // style={{ background: stringToHslColor(userContext.email) }}
                >
                  {userContext.firstName.charAt(0).toUpperCase()}
                </Avatar>
              ) : (
                ""
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={profileAnchorEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openProfile}
              onClose={handleClose}
            >
              {/* <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                <ListItemIcon className={classes.minWidth}>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.minWidth}>
                  <PowerSettingsNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </MenuItem>
            </Menu>
          </div>

          {/* <img src={logo_company} alt="logo" className={classes.logo} /> */}
          {/* <Avatar src="/images/icon-fidelity-title.png" className={classes.logo} variant="square" /> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !adminDrawerContext && classes.drawerPaperClose
          ),
        }}
        open={adminDrawerContext}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} className={classes.text}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider className={classes.divider} />
        <List className={classes.text}>
          <Tooltip title="Dashboard" placement="left">
            <ListItem button component={Link} to="/">
              <ListItemIcon className={classes.text}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Floorplan" placement="left">
            <ListItem button component={Link} to="/floorplan">
              <ListItemIcon className={classes.text}>
                <FloorplanIcon />
                {/* <BorderOuterIcon /> */}
              </ListItemIcon>
              <ListItemText primary="Floorplan" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Manage Store" placement="left">
            <ListItem button component={Link} to="/managestore">
              <ListItemIcon className={classes.text}>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Store" />
            </ListItem>
          </Tooltip>
        </List>
        <List className={clsx(classes.text, classes.bottomMenu)}>
          <Divider className={classes.divider} />
          {/* <Tooltip title="Accounts" placement="left">
            <ListItem button component={Link} to="/accounts">
              <ListItemIcon className={classes.text}>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Accounts" />
            </ListItem>
          </Tooltip> */}
          <Tooltip title="Users" placement="left">
            <ListItem button  >
              <ListItemIcon className={classes.text}>
                <Group />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Tooltip>
          {/* <Tooltip title="Developer API" placement="left">
            <ListItem button component="a" href={`${process.env.REACT_APP_FIDELITY_API_URL}/swagger`} target="new">
              <ListItemIcon className={classes.text}>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Dev API" />
            </ListItem>
          </Tooltip> */}
        </List>
        <Divider className={classes.divider} />
      </Drawer>
    </div>
  );
}
