import React, { useEffect } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Mattertag } from '@mp/bundle-sdk/sdk';
import apiService from '../../services/api.service';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: 'absolute',
    },
    dialogPaper: {
        background: '#ffffff',
        color: '#222222',
    },
    dialogContentText: {
        color: '#222222 !important',
    },
    saveButton: {
        fontFamily: 'CircularBook, sans-serif',
        textTransform: 'unset',
        background: '#222222',
        color: '#ffffff',
        '&:hover': {
            background: '#ee1f3b',
            color: '#ffffff',
        }
    },
    cancelButton: {
        fontFamily: 'CircularBook, sans-serif',
        textTransform: 'unset',
        fontWeight: 500,
        borderColor: '#222222',
        color: '#222222',
        '&:hover': {
            color: '#ee1f3b',
            borderColor: '#ee1f3b',
            background: 'transparent',
        }
    },
    textFieldOutline: {
        borderColor: '#212529 !important'
    },
    dialogActionContainer: {
        padding: 16,
    },
    menuIconContainer: {
        // position: 'absolute',
        // bottom: 26,
        // right: 99,
        zIndex: 100,
        margin: 6,
    },
    menuIconButton: {
        backgroundColor: '#ee1f3b',
        // borderRadius: '25%',
        padding: 4,
        border: 'none',
        color: '#fff',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            backgroundColor: '#ee1f3b',
        }
    },
    menuIconButtonActive: {
        backgroundColor: '#fff',
        // borderRadius: '25%',
        padding: 8,
        border: 'none',
        color: '#ee1f3b',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            backgroundColor: '#ebebeb',
        }
    },
    animatedIconClose: {
        animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`
    },
    animatedIconAdd: {
        animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
    },
    "@keyframes myEffect": {
        "0%": {
            transform: 'rotateZ(90deg)',
            opacity: 0,
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)",
        }
    },
    "@keyframes myEffectExit": {
        "0%": {
            opacity: 0,
            transform: "rotateZ(-90deg)"
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)"
        }
    }
}));

type Props = {
    open: boolean;
    onClose: () => void;
    handleIsRemoveTagToggle: () => void;
    actionState: string;
    onSave: (sid: string) => void;
    tagData?: Mattertag.MattertagData;
}

const RemoveTag: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { open, onClose, handleIsRemoveTagToggle, actionState, onSave, tagData } = props;

    useEffect(() => {
        console.log("tagData", tagData);
    }, [open])

    const handleSaveClick = async () => {
        const position = objToString(tagData.anchorPosition);

        try {
            const apiResult = await apiService.deleteTag(tagData.label, position);
            console.log('====================================');
            apiResult && console.log("apiResult >>>", apiResult);
            console.log('====================================');
            onSave(tagData.sid);
        }
        catch (error) {
            console.log('error !!!', error.response);
            if (error.response && error.response.data.errors) {
                console.log("save product error", Object.values(error.response.data.errors)[0]);
            }
        }
    }

    const objToString = (obj: any) => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');

    return (<>
        <Dialog
            onClose={onClose}
            open={open}
            style={{ position: 'absolute' }}
            container={() => document.getElementById('parent-main')}
            classes={{ paper: classes.dialogPaper }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop,
                },
            }}
        >
            <DialogTitle>Remove Tag</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.dialogContentText}>
                    {`Are you sure you want to remove this tag?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActionContainer }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="default"
                    classes={{ root: classes.cancelButton }}
                >
                    No
                </Button>
                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="default"
                    classes={{ root: classes.saveButton }}
                    autoFocus
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
        <Box className={classes.menuIconContainer}>
            <Tooltip title="Remove Tag" arrow placement="top">
                <IconButton
                    disableRipple
                    className={actionState === "2" ? classes.menuIconButtonActive : classes.menuIconButton}
                    onClick={handleIsRemoveTagToggle}
                >
                    {actionState === "2" ? <CloseRoundedIcon className={classes.animatedIconClose} /> :
                        <DeleteIcon className={classes.animatedIconAdd} />}
                </IconButton>
            </Tooltip>
        </Box>
    </>
    )
}

export default RemoveTag;