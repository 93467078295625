import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, makeStyles, Radio, RadioGroup, TextField, Tooltip, Typography } from '@material-ui/core';
import { Mattertag } from '@mp/bundle-sdk/sdk';
import apiService from '../../services/api.service';
// import useProduct from '../../hooks/useProduct';
import { ProductPreview, useProductPreview } from '../../hooks/useProductPreview';
import { ProductPrice } from './ProductPrice';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useTag, { Tagdata } from '../../hooks/useTag';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: 'absolute',
    },
    dialogPaper: {
        background: '#ffffff',
        color: '#222222',
    },
    dialogContentText: {
        color: '#222222 !important',
    },
    saveButton: {
        fontFamily: 'CircularBook, sans-serif',
        textTransform: 'unset',
        background: '#222222',
        color: '#ffffff',
        '&:hover': {
            background: '#ee1f3b',
            color: '#ffffff',
        }
    },
    cancelButton: {
        fontFamily: 'CircularBook, sans-serif',
        textTransform: 'unset',
        fontWeight: 500,
        borderColor: '#222222',
        color: '#222222',
        '&:hover': {
            color: '#ee1f3b',
            borderColor: '#ee1f3b',
            background: 'transparent',
        }
    },
    disableSaveButton: {
        color: '#ffffff85 !important',
        backgroundColor: '#9e9e9e !important'
    },
    textFieldColor: {
        color: '#212529',
    },
    textFieldOutline: {
        borderColor: '#212529 !important'
    },
    textFieldErrorOutline: {
        borderColor: '#dc3545 !important'
    },
    dialogActionContainer: {
        padding: 16,
    },
    menuIconContainer: {
        // position: 'absolute',
        // bottom: 26,
        // right: 144,
        zIndex: 100,
        margin: 6,
    },
    menuIconButton: {
        backgroundColor: '#ee1f3b',
        // borderRadius: '25%',
        padding: 4,
        border: 'none',
        color: '#fff',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            backgroundColor: '#ee1f3b',
        }
    },
    menuIconButtonActive: {
        backgroundColor: '#fff',
        // borderRadius: '25%',
        padding: 8,
        border: 'none',
        color: '#ee1f3b',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            backgroundColor: '#ebebeb',
        }
    },
    animatedIconClose: {
        animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`
    },
    animatedIconAdd: {
        animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
    },
    "@keyframes myEffect": {
        "0%": {
            transform: 'rotateZ(90deg)',
            opacity: 0,
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)",
        }
    },
    "@keyframes myEffectExit": {
        "0%": {
            opacity: 0,
            transform: "rotateZ(-90deg)"
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)"
        }
    },
    formControl: {
        display: 'flex',
        position: 'relative',
    },
    formGroup: {
        // justifyContent: 'space-evenly',
        flexWrap: 'nowrap',
        padding: theme.spacing(1, 0),
    },
    formLabelRoot: {
        margin: theme.spacing(1, 0),
        '& .Mui-checked + $formLabel': {
            borderColor: '#212529',
        },
    },
    formLabel: {
        border: '2px solid transparent',
        display: 'block',
        width: 125,
        padding: theme.spacing(0.5),
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
        }
    },
    radioRoot: {
        display: 'none',
    }
}));

type Props = {
    open: boolean;
    onClose: () => void;
    handleIsEditTagToggle: () => void;
    actionState: string;
    onSave: (sid: string, properties: any) => void;
    tagData: Mattertag.MattertagData;
}

type BrandProps = {
    brand: string
}

const BrandImage = ({ brand }: BrandProps) => {
    switch (brand) {
        case 'body':
            return <img src={'../../assets/images/brand-body.svg'} alt={brand} />;
        case 'rubi':
            return <img src={'../../assets/images/brand-rubi.svg'} alt={brand} />;
        default:
            return <img src={'../../assets/images/brand-cotton-on.svg'} alt={brand} />;
    }
}

const EditTagLabel: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { open, onClose, handleIsEditTagToggle, actionState, onSave, tagData } = props;
    const [productSku, setProductSku] = useState<string>("");
    // const product: any = useProduct(productSku);
    const [productData, setProductData] = useState<ProductPreview>();
    const [imgSelected, setImgSelected] = useState<string>("0");
    const [productSkuPreview, setProductSkuPreview] = useState<string>("")
    const productPreview: ProductPreview = useProductPreview(productSkuPreview);
    const tag: Tagdata = useTag(tagData?.label, tagData?.anchorPosition);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (open && actionState === "1") {
            setIsLoading(true);
        }
        return () => {
            if (actionState === "1") {
                setProductSkuPreview("");
                setProductData(null);
            }
        }
    }, [open])

    useEffect(() => {
        if (tagData && actionState === "1") {
            setProductSku(tagData.label);
            setProductSkuPreview(tagData.label);
        }
    }, [tagData])

    const handleProductSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProductSku(event.target.value);
    }

    const handleSelectImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImgSelected((event.target as HTMLInputElement).value)
    }

    useEffect(() => {
        if (productData) {
            setIsLoading(false);
            setProductSku("");
        }
    }, [productData])

    useEffect(() => {
        if (tag && actionState === "1") {
            setImgSelected(tag.productImageUrl);
        }
    }, [tag])

    useEffect(() => {
        if (productPreview && actionState === "1") {
            setProductData(productPreview);
            if (productPreview.productSKU === tag.label) {
                setImgSelected(tag.productImageUrl);
            }
        }
    }, [productPreview])

    const handleSaveClick = async () => {
        const position = objToString(tagData.anchorPosition);
        try {
            const apiResult = await apiService.updateProductTag(
                tagData.label,
                position,
                productData.productSKU,
                productData.productSKU,
                productData.brand,
                productData.price,
                productData.productName,
                imgSelected,
                productData.productUrl
            );
            console.log('====================================');
            apiResult && console.log("apiResult >>>", apiResult);
            console.log('====================================');
            onSave(tagData.sid, { label: productData.productSKU })
        }
        catch (error) {
            console.log('error !!!', error.response);
            if (error.response && error.response.data.errors) {
                console.log("save product error", Object.values(error.response.data.errors)[0]);
            }
        }
    }

    const objToString = (obj: any) => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');

    const handlePreviewButtonClick = () => {
        setImgSelected("");
        setProductSkuPreview(productSku);
        setIsLoading(true);
    }

    return (
        <>
            <Dialog
                onClose={onClose}
                open={open}
                style={{ position: 'absolute' }}
                container={() => document.getElementById('parent-main')}
                classes={{ paper: classes.dialogPaper }}
                BackdropProps={{
                    classes: {
                        root: classes.backdrop,
                    },
                }}
            >
                <DialogTitle>Edit Tag</DialogTitle>
                <DialogContent>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            <TextField
                                placeholder={`Product SKU`}
                                variant="outlined"
                                value={productSku}
                                onChange={handleProductSkuChange}
                                fullWidth
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.textFieldOutline,
                                        input: classes.textFieldColor
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handlePreviewButtonClick}
                                color="default"
                                classes={{ root: classes.saveButton }}
                                startIcon={isLoading ? <CircularProgress size={18} color="inherit" /> : <ImageSearchIcon />}
                                disabled={isLoading || !productSku}
                            >
                                Preview
                            </Button>
                        </Grid>
                    </Grid>
                    {!isLoading && productData && <Box my={2} style={{ color: '#212529' }}>
                        <Box mb={0.5}>
                            <BrandImage brand={productData.brand} />
                        </Box>
                        <Typography variant="h6" color='inherit'>
                            {productData.productName}
                        </Typography>
                        <PerfectScrollbar>
                            <FormControl component="fieldset" classes={{ root: classes.formControl }}>
                                <RadioGroup aria-label="icon" name="icon1" row value={imgSelected || productData.productImages[0]} onChange={handleSelectImageChange} classes={{ row: classes.formGroup }}>
                                    {productData?.productImages.map((imgUrl: string, index: any) =>
                                        <FormControlLabel key={index}
                                            value={imgUrl}
                                            control={<Radio classes={{ root: classes.radioRoot }} />}
                                            label={
                                                <img alt={productData.productName} src={imgUrl} />
                                            }
                                            classes={{ root: classes.formLabelRoot, label: classes.formLabel }}
                                        />)}

                                </RadioGroup>
                            </FormControl>
                        </PerfectScrollbar>
                        <ProductPrice price={productData.price} />
                        <Box my={1}>
                            <Typography variant="body2" color='inherit'>
                                {`Product Code: ${productData.productSKU}`}
                            </Typography>
                        </Box>
                    </Box>}
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActionContainer }}>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        color="default"
                        classes={{ root: classes.cancelButton }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={handleSaveClick}
                        variant="contained"
                        color="default"
                        classes={{ root: classes.saveButton, disabled: classes.disableSaveButton }}
                        autoFocus
                        disabled={!productData}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Box className={classes.menuIconContainer}>
                <Tooltip title="Edit Tag" arrow placement="top">
                    <IconButton
                        disableRipple
                        className={actionState === "1" ? classes.menuIconButtonActive : classes.menuIconButton}
                        onClick={handleIsEditTagToggle}
                    >
                        {actionState === "1" ? <CloseRoundedIcon className={classes.animatedIconClose} /> :
                            <EditRoundedIcon className={classes.animatedIconAdd} />}
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    )
}

export default EditTagLabel;