import { Vector3 } from "@mp/bundle-sdk/sdk";
import { useEffect, useState } from "react";
import apiService from "../services/api.service";

export type Tagdata = {
    anchorPosition: Vector3;
    description: string;
    label: string;
    media: {
        type: string,
        src: string
    };
    productBrand: string;
    productImageUrl: string;
    productName: string;
    productPrice: string;
    productSKU: string;
    productUrl: string;
    stemVector: Vector3
    tagId: string;
}

export default function useTag(label: string, anchorPosition: Vector3) {
    const [tag, setTag] = useState<Tagdata>();
    const objToString = (obj: any) => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');

    useEffect(() => {
        const loadTag = async () => {
            const anchorPositionString = objToString(anchorPosition);
            let result = await apiService.getTagByLabelAndPosition(label, anchorPositionString);
            setTag(result.data);
            console.log("Tag", result.data);
        };

        if (label && anchorPosition) {
            loadTag();
        }
    }, [label, anchorPosition])

    return tag;
}