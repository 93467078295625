import React, { forwardRef } from 'react';

interface Props {
  src: string;
}

export type Ref = HTMLIFrameElement;

export const Frame = forwardRef<Ref, Props>((props, ref) => {
  return (
    <div className='frame'>
      <iframe id='sdk-iframe' className='frame' src={props.src + '&title=0&qs=1&hr=0&brand=0&help=0'} ref={ref}></iframe>
    </div>
  );
})
