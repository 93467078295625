import { createTheme } from '@material-ui/core/styles';


export const darkTheme = createTheme({
  palette: {
    type: "dark",
    adminDrawer: {
      main: "#000",
      text: "#fff",
      button: "#fff",
      drawer: "#424242",
      divider: "#595959"
    },
    primary: {
      main: "#BB86FC",
      //main: "#000"
    }
  },
});
