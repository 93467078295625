import { Box, FormControlLabel, IconButton, makeStyles, Paper, RadioGroup, Slide, Typography, Radio } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import clsx from 'clsx';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

const useStyles = makeStyles((theme) => ({
    settingButtonContainer: {
        position: 'absolute',
        bottom: 16,
        left: 86,
        zIndex: 100,
        borderRadius: '50%',
        boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    },
    settingIconButton: {
        backgroundColor: 'transparent',
        // borderRadius: '25%',
        padding: 2,
        border: '2px solid #fff',
        color: '#fff',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            // backgroundColor: '#ee1f3b',
        }
    },
    svgIcon: {
        width: 18,
        height: 18,
    },
    settingMenuRoot: {
        minWidth: 200,
        maxWidth: 280,
        position: 'absolute',
        left: 86,
        bottom: 52,
        background: '#ffffff',
        padding: 8,
        zIndex: 1,
    },
    fontHeader: {
        fontFamily: 'CircularLight, sans-serif !important',
        fontWeight: 'bold',
        color: theme.palette.getContrastText('#ffffff'),
    },
    radioGroup: {
        justifyContent: 'space-between',
        padding: 2,
        // border: '1px solid',
        borderRadius: 50,
        background: '#afafaf47',
        // background: '#ffffff',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    },
    labelContainer: {
        display: 'flex',
        color: theme.palette.getContrastText('#ffffff'),
        justifyContent: 'space-between',
        '& .MuiTypography-root': {
            textAlign: 'center',
            width: 43,
        }
    },
    selectedText: {
        color: '#ee1f3b !important',
    },
    controlLabel: {
        color: theme.palette.getContrastText('#ffffff'),
        alignItems: 'start',
        margin: 4,
        '& .MuiTypography-body1': {
            fontFamily: 'CircularLight, sans-serif !important',
            fontSize: '0.875rem',
        }
    },
    radioIcon: {
        color: '#00000080',
        padding: '0px 9px !important',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    subText: {
        color: theme.palette.getContrastText('#ffffff'),
        fontFamily: 'CircularLight, sans-serif !important',
        marginLeft: 45,
        marginTop: -8,
        marginRight: 4,
        marginBottom: 4,
    },
    backdrop: {
        width: '100%',
        background: 'transparent',
        height: '100%',
        position: 'absolute',
        top: 0,
        // left: 0,
        // right: 0,
        // bottom: 0,
        // zIndex: 1,

    }
}));

interface Props {
    navigationValue: string;
    handleNavigationValueChange: React.Dispatch<React.SetStateAction<string>>;
}

const OptionSetting: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { navigationValue, handleNavigationValueChange } = props;
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const settingRef = useRef<HTMLInputElement>(null);

    const handleToggleMenu = () => {
        setOpenMenu((prevState) => !prevState);
    }

    const handleClickOutside = () => {
        setOpenMenu(false);
    }

    useOnClickOutside(settingRef, handleClickOutside);

    const handleRadioValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleNavigationValueChange(e.target.value);
    }

    return (<>
        <div ref={settingRef}>
            <Slide direction="up" in={openMenu} mountOnEnter unmountOnExit>
                <Paper className={classes.settingMenuRoot} >
                    <Typography variant="body2" gutterBottom className={classes.fontHeader} style={{ margin: 4, }}>{`Product Point interactive:`}</Typography>
                    <RadioGroup name="navigation2" value={navigationValue} onChange={handleRadioValueChange}>
                        <FormControlLabel value="1"
                            className={clsx(classes.controlLabel, { [classes.selectedText]: navigationValue === "1" })}
                            control={<Radio classes={{ root: classes.radioIcon, checked: classes.selectedText }} />}
                            label="Jump in only "
                        />
                        <Typography variant="caption" color="textSecondary" className={classes.subText}>{`Jump to the product when clicking on the point.`}</Typography>
                        <FormControlLabel value="2"
                            className={clsx(classes.controlLabel, { [classes.selectedText]: navigationValue === "2" })}
                            control={<Radio classes={{ root: classes.radioIcon, checked: classes.selectedText }} />}
                            label="Jump in/out "
                        />
                        <Typography variant="caption" color="textSecondary" className={classes.subText}>{`Jump to the product when clicking on the point, then back to the last position when closing the modal.`}</Typography>
                        <FormControlLabel value="0"
                            className={clsx(classes.controlLabel, { [classes.selectedText]: navigationValue === "0" })}
                            control={<Radio classes={{ root: classes.radioIcon, checked: classes.selectedText }} />} label="None"
                        />
                    </RadioGroup>
                </Paper>
            </Slide>
            <Box className={classes.settingButtonContainer}>
                <IconButton
                    disableRipple
                    className={classes.settingIconButton}
                    onClick={handleToggleMenu}
                >
                    <SettingsRoundedIcon classes={{ root: classes.svgIcon }} />
                </IconButton>
            </Box>
        </div>
        {openMenu && <div className={classes.backdrop}></div>}
    </>
    )
}

export default OptionSetting