import { Box, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React, { forwardRef, useEffect, useState } from 'react';
// import Papa from 'papaparse';
import LinkButton from '../styled-components/LinkButton';
import CloseIconButton from '../styled-components/CloseIconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import useTag, { Tagdata } from '../hooks/useTag';
import { Vector3 } from '@mp/bundle-sdk/sdk';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        // flexGrow: 1,
        position: 'relative',
        // minWidth: 850,
        maxWidth: 900,
        padding: theme.spacing(2.5),
        background: '#fff',
        color: '#000',
    },
    imageDetails: {
        // fontSize: 14,
        // padding: theme.spacing(0, 2.5),
    },
    poweredBy: {
        textAlign: 'right',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        fontSize: 14,
    },
    productName: {
        maxWidth: 285,
    },
    productImageContainer: {
        maxWidth: 285,
        width: '100%',
    },
    productImage: {
        outline: 'none',
        // maxWidth: 285,
        display: 'block',
        margin: 'auto',
        width: '100%',
    },
    productPrice: {
        lineHeight: 1.4,
    },
    supPrice: {
        fontSize: ".875rem",
        top: "-0.5rem",
        lineHeight: 0,
        position: "relative",
        verticalAlign: "baseline",
    },
    closeIconButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#707070',
    },
    viewButton: {
        fontFamily: 'CircularBook, sans-serif',
    }
}));

const PriceTag = (props: any) => {
    const classes = useStyles();
    const { price } = props;
    const [priceSplite, setPriceSplite] = useState([])
    useEffect(() => {
        const priceSplite = price.split(".")
        setPriceSplite(priceSplite)
    }, [price])

    return (
        <Typography variant='h5' component="span" className={classes.productPrice}>
            {`${priceSplite[0]}`}
            <sup className={classes.supPrice}>{`.${priceSplite[1]}`}</sup>
        </Typography>
    );
}

interface Props {
    close: () => void;
    label: string;
    anchorPosition: Vector3;
}

const ModalBodyImage: React.ForwardRefRenderFunction<unknown, Props> = (props: Props, ref: React.Ref<unknown>) => {
    const classes = useStyles();
    const { label, anchorPosition, close } = props;
    const [imgLoading, setImgLoading] = useState(true);
    const tag: Tagdata = useTag(label, anchorPosition);

    const getBrandImage = (brand: string) => {
        switch (brand) {
            case 'body':
                return '../../assets/images/brand-body.svg';
            case 'rubi':
                return '../../assets/images/brand-rubi.svg';
            default:
                return '../../assets/images/brand-cotton-on.svg';
        }
    }

    return (
        <Paper className={classes.paper} ref={ref}>
            <CloseIconButton className={classes.closeIconButton} onClick={close} />
            {tag ? <Grid container direction="column">
                <Grid item xs={12}>
                    <Box mb={0.5}>
                        <img src={getBrandImage(tag.productBrand)} />
                    </Box>
                    <Typography variant="h5" gutterBottom className={classes.productName} >
                        {tag.productName}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.productImageContainer} style={{ display: imgLoading ? "none" : "block" }}>
                        <img src={tag.productImageUrl} onLoad={() => setImgLoading(false)} alt="media" className={classes.productImage} />
                    </Box>
                    <Skeleton variant="rect" width={285} height={427} style={{ display: imgLoading ? "block" : "none" }} />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.imageDetails}>
                        <Box my={1}>
                            <PriceTag price={tag.productPrice} />
                        </Box>
                        <Box my={1}>
                            <Typography variant="body2">
                                Product Code: {tag.productSKU}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <LinkButton href={tag.productUrl} target="_blank" rel="noreferrer">
                                <Typography variant="h6" className={classes.viewButton}>
                                    View Details
                                </Typography>
                            </LinkButton>
                        </Box>

                    </div>
                </Grid>
            </Grid> :
                <Box>
                    <Skeleton variant="text" width={90} height={18} />
                    <Skeleton variant="rect" width={285} height={400} />
                    <Skeleton variant="text" width={90} height={18} />
                    <Skeleton variant="text" height={16} />
                    <Skeleton variant="rect" width={285} height={44} />
                </Box>}
        </Paper>
    );
}

export default forwardRef(ModalBodyImage);