import axios, { AxiosRequestConfig } from 'axios';
import authService from './auth.service';

class apiHelperService {
  getAuthClient = () => {
    let storedUser = JSON.parse(sessionStorage.getItem('user'));
    const defaultOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let instance = axios.create(defaultOptions);

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.request.use(function (config) {
      config.headers.Authorization = storedUser ? `Bearer ${storedUser.accessToken}` : '';
      return config;
    });

    return instance;
  };

  internalAPICall = async (call: any, httpMethod: string, params: any) => {
    try {
      const res = await call;
      return res.data;
    } catch (err) {
      if (err.response.status === 401) {
        let storedUser = JSON.parse(sessionStorage.getItem("user"));
        if (storedUser && storedUser.refreshToken) {
          await authService.loginWithRefreshToken(storedUser.refreshToken);
          try {
            let call;
            if (httpMethod === 'post') {
              call = this.getAuthClient().post(params.url, params.formData, params.params);
            } else if (httpMethod === 'put') {
              call = this.getAuthClient().put(params.url, params.formData, params.params);
            } else if (httpMethod === 'patch') {
              call = this.getAuthClient().patch(params.url, params.params);
            } else if (httpMethod === 'delete') {
              call = this.getAuthClient().delete(params.url, params.params);
            } else {
              call = this.getAuthClient().get(params.url, params.config);
            }

            const response = await call;
            return response.data;
          }
          catch (err) {
            throw err;
          }
        }
      } else {
        console.log(
          "error message was not 401 it was " + err.response.status + " ."
        );
        throw err;
      }
    };
  };

  getCallAPIWrapper(url: string, config?: AxiosRequestConfig<any>) {
    return this.internalAPICall(this.getAuthClient().get(url, config), 'get', { url, config });
  }

  postCallAPIWrapper(url: string, formData?: any, params?: any) {
    return this.internalAPICall(this.getAuthClient().post(url, formData, params), 'post', { url, formData, params });
  }

  putCallAPIWrapper(url: string, formData?: any, params?: any) {
    return this.internalAPICall(this.getAuthClient().put(url, formData, params), 'put', { url, formData, params });
  }

  patchCallAPIWrapper(url: string, params?: any) {
    return this.internalAPICall(this.getAuthClient().patch(url, params), 'patch', { url, params });
  }

  deleteCallAPIWrapper(url: string, params?: any) {
    return this.internalAPICall(this.getAuthClient().delete(url, params), 'delete', { url, params });
  }
}

export default new apiHelperService();
