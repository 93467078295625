/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cube_Body_0: THREE.Mesh
    Cube001_Body_0: THREE.Mesh
    Cube002_Material_0: THREE.Mesh
    Cube003_Material_0: THREE.Mesh
    Cube004_Material_0: THREE.Mesh
    Cube005_Material_0: THREE.Mesh
    Torus_Material_0: THREE.Mesh
    Cube006_Material_0: THREE.Mesh
    Cube007_Material_0: THREE.Mesh
    Cube008_Material_0: THREE.Mesh
    Cube009_Material_0: THREE.Mesh
    Cube010_Material_0: THREE.Mesh
    Cube011_Material_0: THREE.Mesh
    Cube012_Material_0: THREE.Mesh
    Cube013_Material_0: THREE.Mesh
    Cylinder_Material_0: THREE.Mesh
  }
  materials: {
    Body: THREE.MeshStandardMaterial
    Material: THREE.MeshStandardMaterial
  }
}

export function GiftBox(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('../../assets/gltfs/gift_box.gltf') as GLTFResult
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <group position={[0, 100, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
              <mesh geometry={nodes.Cube_Body_0.geometry} material={materials.Body} />
            </group>
            <group position={[407.62, 590.39, -100.55]} rotation={[1.89, 0.88, -2.05]} scale={100}>
              <group rotation={[Math.PI / 2, 0, 0]} />
            </group>
            <group position={[735.89, 495.83, 692.58]} rotation={[-Math.PI, 0.76, 2.68]} scale={100} />
            <group position={[0, 183.12, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
              <mesh geometry={nodes.Cube001_Body_0.geometry} material={materials.Body} />
            </group>
            <group position={[0, 100, -99.17]} rotation={[-Math.PI / 2, 0, 0]} scale={[17.5, 3, 100]}>
              <mesh geometry={nodes.Cube002_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 100, 99.17]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[17.5, 3, 100]}>
              <mesh geometry={nodes.Cube003_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[-99.17, 100, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[17.5, 3, 100]}>
              <mesh geometry={nodes.Cube004_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[99.17, 100, 0]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[17.5, 3, 100]}>
              <mesh geometry={nodes.Cube005_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 205.8, 0]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[10, 20, 100]}>
              <mesh geometry={nodes.Torus_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 244.37, 24.12]} rotation={[-Math.PI / 2, 0, 0]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube006_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[24.27, 248.33, 0.06]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube007_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[-24.34, 248.33, 0.06]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube008_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 244.37, -23.8]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube009_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 223.51, 9.81]} rotation={[-0.6, 0, Math.PI]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube010_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[-8.55, 227.47, -0.6]} rotation={[-Math.PI / 2, -0.98, Math.PI / 2]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube011_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[8.7, 227.47, -0.6]} rotation={[-Math.PI / 2, 0.98, -Math.PI / 2]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube012_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 223.51, -10.15]} rotation={[-2.55, 0, 0]} scale={[100, 68, 40]}>
              <mesh geometry={nodes.Cube013_Material_0.geometry} material={materials.Material} />
            </group>
            <group position={[0, 247.61, 0]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[40, 18, 100]}>
              <mesh geometry={nodes.Cylinder_Material_0.geometry} material={materials.Material} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('../../assets/gltfs/gift_box.gltf')
