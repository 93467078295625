import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Theme, withStyles } from '@material-ui/core';

const CloseIconButton = withStyles(({ spacing }: Theme) => ({
    root: {
        padding: 0,
    }
}))((props: any) => (
    <IconButton {...props}>
        <CloseIcon />
    </IconButton>
));

export default CloseIconButton;