import { useEffect, useState } from "react";
import apiService from "../services/api.service";

export default function useTags(spaceId: string) {
    const [tags, setTags] = useState();

    useEffect(() => {
        const loadTags = async () => {
            let result = await apiService.getTagsBySpaceId(spaceId);
            setTags(result.data);
            console.log("Tagsss", result.data);
        };

        if (spaceId) {
            loadTags();
        }
    }, [])

    return tags;
}