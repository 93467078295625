import { makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    productPrice: {
        lineHeight: 1.4,
    },
    supPrice: {
        fontSize: ".875rem",
        top: "-0.5rem",
        lineHeight: 0,
        position: "relative",
        verticalAlign: "baseline",
    }
}));

type Props = {
    price: string;
}

export const ProductPrice = (props: Props) => {
    const classes = useStyles();
    const { price } = props;
    const [priceSplite, setPriceSplite] = useState([]);

    useEffect(() => {
        return () => setPriceSplite([]);
    }, [])

    useEffect(() => {
        const priceSplite = price.split(".")
        setPriceSplite(priceSplite)
    }, [price])

    return (
        <Typography variant='h5' component="span" className={classes.productPrice}>
            {`${priceSplite[0]}`}
            <sup className={classes.supPrice}>{`.${priceSplite[1]}`}</sup>
        </Typography>
    );
}