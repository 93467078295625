import React, { useState } from 'react';
import { lightTheme } from "./themes/lightTheme";
import { ThemeProvider } from "@material-ui/styles";
import { UserContext } from "./contexts/UserContext";
import ProtectedRoute, { ProtectedRouteProps } from './protected.route';
import Login from './pages/Login';
import { ReloadContext } from './contexts/ReloadContext';
import { AdminDrawerContext } from './contexts/AdminDrawerContext';
import Dashboard from './pages/Dashboard';
import Floorplan from './pages/Floorplan/Floorplan';
import ManageStore from './pages/ManageStores/ManageStore';
import PreviewSpace from './components/PreviewSpace';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'element'> = {
    authenticationPath: '/login',
};

let routes = (
    <Routes>
        <Route path="login" element={<Login />} />
        <Route path="dashboard" element={<ProtectedRoute {...defaultProtectedRouteProps} element={<Dashboard />} />} />
        <Route path="floorplan" element={<ProtectedRoute {...defaultProtectedRouteProps} element={<Floorplan />} />} />
        <Route path="managestore" element={<ProtectedRoute {...defaultProtectedRouteProps} element={<ManageStore />} />} />
        <Route path="preview" element={<ProtectedRoute {...defaultProtectedRouteProps} element={<PreviewSpace />} />} />
        <Route path="/" element={<Navigate replace to="dashboard" />} />
    </Routes>
);

const App = () => {
    const [userContext, setUserContext] = useState({ firstName: "", themeColor: "0" });
    const [adminDrawerContext, setAdminDrawerContext] = useState(true);
    const [reloadContext, setReloadContext] = useState();

    return (
        <Router>
            <ThemeProvider theme={lightTheme}>
                <UserContext.Provider value={[userContext, setUserContext]}>
                    <ReloadContext.Provider value={[reloadContext, setReloadContext]}>
                        <AdminDrawerContext.Provider value={[adminDrawerContext, setAdminDrawerContext]}>
                            {routes}
                        </AdminDrawerContext.Provider>
                    </ReloadContext.Provider>
                </UserContext.Provider>
            </ThemeProvider>
        </Router>
    )
}

export default App