import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, makeStyles, Radio, RadioGroup, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import apiService from '../../services/api.service';
import { ProductPreview, useProductPreview } from '../../hooks/useProductPreview';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ProductPrice } from './ProductPrice';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: 'absolute',
    },
    dialogPaper: {
        background: '#ffffff',
        color: '#222222',
    },
    saveButton: {
        fontFamily: 'CircularBook, sans-serif',
        textTransform: 'unset',
        background: '#222222',
        color: '#ffffff',
        '&:hover': {
            background: '#ee1f3b',
            color: '#ffffff',
        }
    },
    disableSaveButton: {
        color: '#ffffff85 !important',
        backgroundColor: '#9e9e9e !important'
    },
    cancelButton: {
        fontFamily: 'CircularBook, sans-serif',
        textTransform: 'unset',
        fontWeight: 500,
        color: '#222222',
        '&:hover': {
            color: '#ee1f3b',
            background: 'transparent',
        }
    },
    textFieldOutline: {
        borderColor: '#212529 !important'
    },
    textFieldErrorOutline: {
        borderColor: '#dc3545 !important'
    },
    textFieldColor: {
        color: '#212529',
    },
    dialogActionContainer: {
        padding: 16,
    },
    menuIconContainer: {
        zIndex: 100,
        margin: 6,
    },
    menuIconButton: {
        backgroundColor: '#ee1f3b',
        padding: 4,
        border: 'none',
        color: '#fff',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            backgroundColor: '#ee1f3b',
        },
        '&.Mui-disabled': {
            backgroundColor: '#ad172b',
        }
    },
    menuIconButtonActive: {
        backgroundColor: '#fff',
        padding: 8,
        border: 'none',
        color: '#ee1f3b',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            backgroundColor: '#ebebeb',
        }
    },
    animatedIconClose: {
        animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`
    },
    animatedIconAdd: {
        animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
    },
    "@keyframes myEffect": {
        "0%": {
            transform: 'rotateZ(90deg)',
            opacity: 0,
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)",
        }
    },
    "@keyframes myEffectExit": {
        "0%": {
            opacity: 0,
            transform: "rotateZ(-90deg)"
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)"
        }
    },
    formControl: {
        display: 'flex',
        position: 'relative',
    },
    formGroup: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        padding: theme.spacing(1, 0),
    },
    formLabelRoot: {
        margin: theme.spacing(1, 0),
        '& .Mui-checked + $formLabel': {
            borderColor: '#212529',
        },
    },
    formLabel: {
        border: '2px solid transparent',
        display: 'block',
        width: 125,
        padding: theme.spacing(0.5),
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
        }
    },
    radioRoot: {
        display: 'none',
    }
}));

type Props = {
    actionState: string;
    open: boolean;
    onClose: () => void;
    tagData: any;
    onSave: (sid: string, properties: any) => void;
    handleToggleAddButton: () => void;
}

const AddTagModal: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { actionState, open, onClose, tagData, onSave, handleToggleAddButton } = props;
    const [label, setLabel] = useState<string>();
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [imgSelected, setImgSelected] = useState<string>("0");
    const [productSkuPreview, setProductSkuPreview] = useState<string>("")
    const productPreview: ProductPreview = useProductPreview(productSkuPreview);

    useEffect(() => {
        if (!open) {
            setLabel('');
            setIsAdd(false);
            console.log("actionState now", actionState);
        }
        return () => {
            setProductSkuPreview("");
        }
    }, [open])

    const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value)
    }

    const handleSaveClick = async () => {
        const position = objToString(tagData.anchorPosition);
        const stemVector = objToString(tagData.stemVector);
        const productImageUrl = productPreview.productImages[Number(imgSelected)];
        try {
            const apiResult = await apiService.createTag(
                '8rqU8VGznQC',
                label,
                '',
                position,
                stemVector,
                'photo',
                '',
                label,
                productPreview.brand,
                productPreview.price,
                productPreview.productName,
                productImageUrl,
                productPreview.productUrl
            );
            if (apiResult) { onSave(tagData.sid, { label: label }); }
        }
        catch (error) {
            console.log('error !!!', error.response);
            if (error.response && error.response.data.errors) {
                console.log("save product error", Object.values(error.response.data.errors)[0]);
            }
        }
    }

    const objToString = (obj: any) => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');

    const handleAddButtonClick = () => {
        if (!isAdd) {
            setIsAdd(true);
            handleToggleAddButton();
        } else {
            setIsAdd(false);
            onClose();
        }
    }

    const handlePreviewButtonClick = () => {
        // console.log("product preview >> ", productPreview);
        setImgSelected("0");
        setProductSkuPreview(label);
    }

    const handleSelectImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImgSelected((event.target as HTMLInputElement).value)
    }

    const getBrandImage = (brand: string) => {
        switch (brand) {
            case 'body':
                return '../../assets/images/brand-body.svg';
            case 'rubi':
                return '../../assets/images/brand-rubi.svg';
            default:
                return '../../assets/images/brand-cotton-on.svg';
        }
    }

    return (<>
        <Dialog
            onClose={onClose}
            open={open}
            style={{ position: 'absolute' }}
            container={() => document.getElementById('parent-main')}
            classes={{ paper: classes.dialogPaper }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop,
                },
            }}
        >
            <DialogTitle id="simple-dialog-title">Add Product</DialogTitle>
            <DialogContent>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs>
                        <TextField
                            placeholder={`Add Product SKU`}
                            variant="outlined"
                            color='primary'
                            value={label}
                            fullWidth
                            onChange={handleLabelChange}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.textFieldOutline,
                                    input: classes.textFieldColor
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handlePreviewButtonClick}
                            color="default"
                            classes={{ root: classes.saveButton }}
                            startIcon={<ImageSearchIcon />}
                            disabled={!label}
                        >
                            Preview
                        </Button>
                    </Grid>
                </Grid>

                {productPreview && <Box my={2} style={{ color: '#212529' }}>
                    <Box mb={0.5}>
                        <img src={getBrandImage(productPreview.brand)} alt={productPreview.brand} />
                    </Box>
                    <Typography variant="h6" color='inherit'>
                        {productPreview.productName}
                    </Typography>
                    <PerfectScrollbar>
                        <FormControl component="fieldset" classes={{ root: classes.formControl }}>
                            <RadioGroup aria-label="icon" name="icon1" row value={imgSelected} onChange={handleSelectImageChange} classes={{ row: classes.formGroup }}>
                                {productPreview?.productImages.map((imgUrl, index) =>
                                    <FormControlLabel key={index}
                                        value={`${index}`}
                                        control={<Radio classes={{ root: classes.radioRoot }} />}
                                        label={
                                            <img alt={productPreview.productName} src={imgUrl} />
                                        }
                                        classes={{ root: classes.formLabelRoot, label: classes.formLabel }}
                                    />)}

                            </RadioGroup>
                        </FormControl>
                    </PerfectScrollbar>
                    <ProductPrice price={productPreview.price} />
                </Box>}
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActionContainer }}>
                <Button
                    onClick={onClose}
                    color="default"
                    classes={{ root: classes.cancelButton }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSaveClick}
                    variant="contained"
                    color="default"
                    classes={{ root: classes.saveButton, disabled: classes.disableSaveButton }}
                    autoFocus
                    disabled={!productPreview}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <Box className={classes.menuIconContainer}>
            <Tooltip title={"Add Tag"} arrow placement="top" >
                <IconButton
                    disableRipple
                    className={isAdd ? classes.menuIconButtonActive : classes.menuIconButton}
                    onClick={handleAddButtonClick}
                // disabled={actionState !== "0" || actionState !== "4"}
                >
                    {isAdd ? <CloseRoundedIcon className={classes.animatedIconClose} /> :
                        <AddRoundedIcon className={classes.animatedIconAdd} />}
                </IconButton>
            </Tooltip>
        </Box>
    </>
    )
}

export default AddTagModal;