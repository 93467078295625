import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import PlayToggleButton from '../styled-components/PlayToggleButton';
import VolumeSlider from '../styled-components/VolumeSlider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: 16,
    left: 20,
    zIndex: 100,
    display: 'flex',
    alignItems: 'flex-end'
  },
  reactPlayer: {},
  dialogPaper: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
  },
  backDrop: {
    position: 'absolute',
    backdropFilter: "blur(6px)",
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  textModal: {
    fontFamily: 'CircularBook, sans-serif',
    color: '#000',
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
    fontFamily: 'CircularBook, sans-serif',
    letterSpacing: 2,
    color: '#ffffff',
    background: '#ee1f3b',
    fontWeight: 'bold',
    '&:hover': {
      color: '#ffffff',
      background: '#ee1f3bc9',
    }
  },
}));

type BgMedia = {
  url?: string[];
  playing?: boolean;
}

// type Props = {
//   mainRef: HTMLDivElement;
// }

const BackgroundMedia: React.FC = () => {
  const classes = useStyles();
  const [url, setUrl] = useState<string>();
  const [playing, setPlaying] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const backgroundMedia: BgMedia = {
    url: [
      '../../assets/audios/Surfaces-SundayBest.mp3',
      '../../assets/audios/NewRadicals-YouGetWhatYouGive.mp3',
      '../../assets/audios/KungsVsCookinOn3Burners-ThisGirl.mp3',
      '../../assets/audios/ChrisValentine-HighLife.mp3',
      '../../assets/audios/VanceJoy-MissingPiece.mp3',
      '../../assets/audios/EAST-LifeGoesOn.mp3',
    ],
    playing: false
  };

  useEffect(() => {
    let backgroundMediaUrl = '';
    if (backgroundMedia && backgroundMedia.url !== undefined) {
      let randomNumber = random1Number();
      let mediaLength = backgroundMedia.url.length;
      let mediaIndex = randomNumber % mediaLength;
      backgroundMediaUrl = backgroundMedia.url[mediaIndex];
    }
    setUrl(backgroundMediaUrl);

    if (backgroundMedia && backgroundMedia.playing !== undefined) {
      setPlaying(backgroundMedia.playing);
    }
  }, [])

  // useEffect(() => {

  // }, [backgroundMedia]);

  // useEffect(() => {
  //   // if (url) {
  //   //   setPlaying(true);
  //   // } else {
  //   //   setPlaying(false);
  //   // }
  //   console.log('====================================');
  //   console.log("Url", url);
  //   console.log('====================================');
  // }, [url]);

  const handlePlayerReady = () => {
    console.log('handlePlayerReady');
  };
  const handlePlayerStart = () => {
    console.log('handlePlayerStart');
  };
  const handlePlayerPlay = () => {
    console.log('handlePlayerPlay');
  };
  const handlePlayerError = (e: any) => {
    console.log('handlePlayerError: ', e);
    setPlaying(false);
  };
  const handlePlayerEnded = () => {
    console.log('handlePlayerEnded');
    nextAudio();
  };

  const handleVolumeChange = (newValue: number) => {
    setVolume(newValue);
  };

  const nextAudio = () => {
    let prevIndex = backgroundMedia.url.indexOf(url);
    if (prevIndex + 1 < backgroundMedia.url.length) {
      setUrl(backgroundMedia.url[prevIndex + 1]);
    } else { setUrl(backgroundMedia.url[0]); }
  }

  const random1Number = () => {
    let number = Date.now();
    let lastDigitStr = String(number).slice(-1);
    const lastDigitNumber = Number(lastDigitStr);
    return lastDigitNumber;
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setPlaying(true);
  }

  return (<>
    <div className={classes.root}>
      <ReactPlayer
        className={classes.reactPlayer}
        url={url}
        playing={playing}
        // loop={true}
        volume={volume}
        width="auto"
        height="auto"
        onReady={handlePlayerReady}
        onStart={handlePlayerStart}
        onPlay={handlePlayerPlay}
        onError={handlePlayerError}
        onEnded={handlePlayerEnded}
      />

      <PlayToggleButton
        selected={playing}
        onChange={() => {
          setPlaying(!playing);
        }}
      />
      {volume >= 0 && <VolumeSlider volume={volume} handleVolumeChange={handleVolumeChange} />}
    </div>
    <Dialog
      open={openModal}
      container={() => document.getElementById('parent-main')}
      classes={{ paper: classes.dialogPaper }}
      disableEscapeKeyDown={true}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      style={{ position: 'absolute' }}
    >
      <DialogTitle id="alert-dialog-title"><img src='../../assets/images/brand-cotton-on.svg' width={120} /></DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Typography variant="h5" color='textPrimary' gutterBottom className={classes.textModal}>
          Welcome to the Cotton On Experience.
        </Typography>
        <Button variant='contained' onClick={handleCloseModal} classes={{ root: classes.buttonRoot }}>Continue</Button>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  </>
  );
};

export default BackgroundMedia;
