import axios from 'axios';

// type Props = {
//   isAuth: boolean;
//   host: string;
// }

class authService {
  // private isAuth: boolean;
  private host: string;

  constructor() {
    // this.isAuth = false;
    this.host = process.env.REACT_APP_AUTH_DOMAIN_URL;
    // this.host = 'https://localhost:44379';
  }

  login = async (username: string, password: string) => {
    try {
      const res = await axios.post(this.host + `/api/v1/Authentication/login`, {
        grant_type: 'password',
        username: username,
        password: password,
      });

      if (res && res.data.data.access_token) {
        sessionStorage.setItem(
          'user',
          JSON.stringify({
            user: username,
            accessToken: res.data.data.access_token,
            refreshToken: res.data.data.refresh_token,
          })
        );

        Promise.resolve({
          user: username,
          accessToken: res.data.data.access_token,
          refreshToken: res.data.data.refresh_token,
        });
      } else {
        throw new Error('failed to login');
      }
    } catch (error) {
      throw error;
    }
  };

  loginFacebook = async (userID: string, accessToken: string) => {
    try {
      const res = await axios.post(this.host + `/api/v1/Authentication/login-facebook`, {
        userID: userID,
        accessToken: accessToken,
      });

      if (res && res.data.access_token) {
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            user: userID,
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          })
        );

        Promise.resolve({
          user: userID,
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        });
      } else {
        throw new Error('failed to login');
      }
    } catch (error) {
      throw error;
    }
  };

  loginLinkedIn = async (accessToken: string) => {
    try {
      const res = await axios.post(this.host + `/api/v1/Authentication/login-linkedin`, {
        accessToken: accessToken,
      });

      if (res && res.data.access_token) {
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            user: " ",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          })
        );

        Promise.resolve({
          user: "",
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        });
      } else {
        throw new Error('failed to login');
      }
    } catch (error) {
      throw error;
    }
  };

  loginGoogle = async (userID: string, accessToken: string) => {
    try {
      const res = await axios.post(this.host + `/api/v1/Authentication/login-google`, {
        userID: userID,
        accessToken: accessToken,
      })

      if (res && res.data.access_token) {
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            user: " ",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          })
        );

        Promise.resolve({
          user: " ",
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        });
      } else {
        throw new Error("failed to login");
      }
    } catch (error) {
      throw error;
    }
  };

  signUpFacebook = (userID: string, accessToken: string) => {
    return axios
      .post(this.host + `/api/v1/account/signup-facebook`, {
        userID: userID,
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: userID,
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: userID,
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        };
      })
      .catch((error) => {
        throw error;
      });
  };

  signUpLinkedIn = (accessToken: string) => {
    return axios
      .post(this.host + `/api/v1/account/signup-linkedin`, {
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: " ",
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: " ",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        };
      })
      .catch((error) => {
        throw error;
      });
  };

  signUpGoogle = (userID: string, accessToken: string) => {
    return axios
      .post(this.host + `/api/v1/account/signup-google`, {
        userID: userID,
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: " ",
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: "",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        };
      })
      .catch((error) => {
        throw error;
      });
  };

  loginWithRefreshToken = async (refreshToken: string) => {
    console.log("LoginWithRefreshToken!!!!!");
    let storedUser = JSON.parse(sessionStorage.getItem('user'));
    try {
      const res = await axios.post(this.host + `/api/v1/Authentication/login`, {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        username: storedUser.user,
      });
      if (res.data.data.access_token) {
        sessionStorage.setItem(
          'user',
          JSON.stringify({
            user: storedUser.user,
            accessToken: res.data.data.access_token,
            refreshToken: res.data.data.refresh_token,
          })
        );
      } else {
        Promise.reject("Request Not Authenticated!");
      }
    } catch (error) {
      Promise.reject("Failed to login with request token");
    }
  };

  logout = () => {
    sessionStorage.removeItem("user");
    return true;
  };

  getAuthenticationContext = () => {
    return JSON.parse(sessionStorage.getItem("user"));
  };
}


export default new authService();