import React from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoveRoundedIcon from '@material-ui/icons/OpenWithRounded';
import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    menuIconContainer: {
        // position: 'absolute',
        // bottom: 26,
        // right: 189,
        zIndex: 100,
        margin: 6,
    },
    menuIconButton: {
        backgroundColor: '#ee1f3b',
        // borderRadius: '25%',
        padding: 4,
        border: 'none',
        color: '#fff',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            backgroundColor: '#ee1f3b',
        }
    },
    menuIconButtonActive: {
        backgroundColor: '#fff',
        // borderRadius: '25%',
        padding: 8,
        border: 'none',
        color: '#ee1f3b',
        // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
        '&:hover': {
            // color: '#ee1f3b',
            // backgroundColor: 'transparent',
            backgroundColor: '#ebebeb',
        }
    },
    animatedIconClose: {
        animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`
    },
    animatedIconAdd: {
        animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
    },
    "@keyframes myEffect": {
        "0%": {
            transform: 'rotateZ(90deg)',
            opacity: 0,
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)",
        }
    },
    "@keyframes myEffectExit": {
        "0%": {
            opacity: 0,
            transform: "rotateZ(-90deg)"
        },
        "100%": {
            opacity: 1,
            transform: "rotateZ(0deg)"
        }
    },
    disableButton: {
        display: 'none',
    }
}));

type Props = {
    handleIsMoveTagToggle: () => void;
    actionState: string;
}

const MoveTag: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { handleIsMoveTagToggle, actionState } = props;
    return (
        <>
            <Box className={classes.menuIconContainer}>
                <Tooltip title="Move Tag" arrow placement="top">
                    <IconButton
                        disableRipple
                        className={actionState === "3" ? classes.menuIconButtonActive : classes.menuIconButton}
                        classes={{ disabled: classes.disableButton }}
                        onClick={handleIsMoveTagToggle}
                    >
                        {actionState === "3" ? <CloseRoundedIcon className={classes.animatedIconClose} /> :
                            <MoveRoundedIcon className={classes.animatedIconAdd} />}
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    )
}

export default MoveTag;