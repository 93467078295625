import React from "react";
import { Navigate } from "react-router-dom";
import authService from "./services/auth.service";

export type ProtectedRouteProps = {
  authenticationPath: string;
  element: JSX.Element;
};

export default function ProtectedRoute({ authenticationPath, element }: ProtectedRouteProps) {
  if (authService?.getAuthenticationContext()?.user) {
    return element;
  } else {
    return <Navigate to={authenticationPath} />;
  }
};