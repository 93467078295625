import { useState, useEffect } from 'react'
import apiService from '../services/api.service';

export type ProductPreview = {
    brand: string;
    price: string;
    productImages: string[];
    productName: string;
    productSKU: string;
    productUrl: string;
}

export const useProductPreview = (productSKU: string) => {
    const [product, setProduct] = useState<ProductPreview>();

    useEffect(() => {
        const loadProducts = async () => {
            let result = await apiService.getProductPreviewByProsuctSKU(productSKU);
            console.log("product result", result.data);
            setProduct(result.data);
        };

        if (productSKU) {
            loadProducts();
        }
        return () => {
            setProduct(null);
        }
    }, [productSKU])

    return product;
}