import React, { useEffect, useRef, useState } from 'react';
import {
  Frame, GetSDK, initComponents, SceneComponent, ISceneNode, sdkKey,
  ComponentInteractionType, IComponentEventSpy
} from '@mp/common';
// import { AppState } from '../AppState';
import { SceneLoader } from '../SceneLoader';
import { cameraInputType } from '@mp/common/src/sdk-components/Camera';
import { Vector3, Quaternion, Euler, Matrix4 } from 'three';
import ModalContainer from './ModalContainer';
// import TagsMockData from '../../assets/tags-mockdata';
import LogoHeader from './LogoHeader';
import BackgroundMedia from './BackgroundMedia';
// import NavigationMenu from './NavigationMenu';
import FidelityIconLink from './FidelityIconLink';
import { Mattertag } from '@mp/bundle-sdk/sdk';
import OptionSetting from './OptionSetting';
import { useSearchParams } from 'react-router-dom';
import RewardModal from './RewardModal';
import useTags from '../hooks/useTags';
import AddTagModal from './admin/AddTagModal';
import RemoveTag from './admin/RemoveTag';
import EditTagLabel from './admin/EditTagLabel';
import MoveTag from './admin/MoveTag';
import apiService from '../services/api.service';
// import ReactGA from "react-ga";

// ReactGA.initialize("G-77H48FK4ZL");
// ReactGA.pageview(window.location.pathname + window.location.search);

interface Props {
  spaceId: string;
}

type SlotNode = {
  node: ISceneNode;
  modelComponent: SceneComponent;
}

enum ActionState {
  none = '0',
  isEdit = '1',
  isRemove = '2',
  isMove = '3',
  isAdd = '4',
}

export function Main(props: Props) {
  const [src, setSrc] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tagData, setTagData] = useState<Mattertag.MattertagData>();
  const [matterportSdk, setMatterportSdk] = useState<any>();
  const [navigationValue, setNavigationValue] = useState<string>("0");
  const [disableNavigationToTag, setDisableNavigationToTag] = useState<boolean>(true);
  const [prevPosition, setPrevPosition] = useState<any>();
  const currentPoseRef = useRef<any>({});
  // const mainRef = useRef<HTMLDivElement>();
  let [, setSearchParams] = useSearchParams();
  const [openRewardModal, setOpenRewardModal] = useState<boolean>(false);
  const tagsList: any = useTags('8rqU8VGznQC');
  const currentUpdatedTagRef = useRef<any>(null);
  const tagRef = useRef<any>(null);
  const [openAddTag, setOpenAddTag] = useState<boolean>(false);
  const [addedTag, setAddedTag] = useState<any>();
  const iFrameRef = useRef<any>(null);
  const [actionState, setActionState] = useState<string>('');
  const [openRemoveTag, setOpenRemoveTag] = useState<boolean>(false);
  const [openEditTag, setOpenEditTag] = useState<boolean>(false);
  // const [tagId, setTagId] = useState<string>('');
  const tagDataRef = useRef<any>(null);

  useEffect(() => {
    const params = objectFromQuery();

    console.log("params", params);
    params.m = params.m || '8rqU8VGznQC'; // space model id 8rqU8VGznQC
    params.play = params.play || '1';
    params.qs = params.qs || '1';
    params.search = '0';
    params.vr = '0';
    params.applicationKey = params.applicationKey || sdkKey;

    const applicationKey = params.applicationKey;
    const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
    setSrc(`./bundle/showcase.html?${queryString}`);

    async function prepareSDK() {
      let sdk = await GetSDK('sdk-iframe', applicationKey);
      if (sdk) {
        setMatterportSdk(sdk);
      }
    }

    prepareSDK();

  }, [])

  useEffect(() => {
    async function initSpace(sdk: any) {
      await initComponents(sdk);
      await createCameraControl(sdk);

      await getPosition(sdk);

      await sdk.Scene.configure((renderer: any, three: any) => {
        renderer.physicallyCorrectLights = true;
        renderer.gammaFactor = 2.2;
        renderer.gammaOutput = true;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.bias = 0.0001;
        renderer.shadowMap.type = three.PCFSoftShadowMap;
      });
      let scene: SceneLoader = new SceneLoader(sdk);

      const slots: SlotNode[] = [];

      const ClickSpy: IComponentEventSpy = {
        eventType: ComponentInteractionType.CLICK,
        onEvent: handleModelClick
      }

      const findSlots = (node: ISceneNode) => {
        let model: SceneComponent = null;
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
          if (component.componentType === 'mp.gltfLoader') {
            model = component;
            model.spyOnEvent(ClickSpy);
          }
        }

        if (model) {
          slots.push({
            node: node,
            modelComponent: model,
          })
        }

      };
      await scene.load('AAWs9eZ9ip6', findSlots);

      console.log('this sdk', sdk);

      await initMattertags(sdk);
      await hideBillboard(sdk);
      await changeMattertagIcon(sdk);
      await editStemVector();

      //click to open modal
      // sdk.on(sdk.Mattertag.Event.CLICK,
      //   (tagSid: string) => handleTagClicked(sdk, tagSid)
      // );

      const params = getURLParams();
      if (params.locationId) {
        console.log("params.locationId", params.locationId);
        await changeLocation(sdk, params.locationId, params.option);
        // const sweep: any = await getSweepById(sdk, params.locationId);
        // console.log("sweep", sweep);
        // await hideFarProductTags(sdk, sweep.position);
      }

      await sdk.Sweep.current.subscribe(async function (currentSweep: any) {
        console.log("currentSweep", currentSweep);
        if (currentSweep.sid) {
          // window.history.replaceState({}, null, `?id=${currentSweep.sid}`);
          setSearchParams({ sid: currentSweep.sid });
          // console.log('Currently at sweep', currentSweep);
          await hideFarProductTags(sdk, currentSweep.position);
        }
      });

      sdk.Camera.pose.subscribe(function (pose: any) {
        let isEqual = equals(pose, currentPoseRef.current);
        if (!isEqual) {
          currentPoseRef.current = JSON.parse(JSON.stringify(pose));
        }
      });

      sdk.Mattertag.data.subscribe({
        onRemoved: function (index: any, item: any, collection: any) {
          console.log('tag removed :', index);
        },
        onUpdated: function (index: any, item: any, collection: any) {
          currentUpdatedTagRef.current = item;
        }
      });

    }
    if (matterportSdk) {
      initSpace(matterportSdk);
      setActionState(ActionState.none);
    }
  }, [matterportSdk])

  const equals = (obj1: any, obj2: any) => {
    return obj1.sweep === obj2.sweep && obj1.rotation.x === obj2.rotation.x && obj1.rotation.y === obj2.rotation.y;
  };

  useEffect(() => {
    if (navigationValue === "0") {
      setDisableNavigationToTag(true);
    } else if (navigationValue === "1") {
      setDisableNavigationToTag(false);
    } else if (navigationValue === "2") {
      setDisableNavigationToTag(false);
    }
  }, [navigationValue])

  useEffect(() => {
    if (matterportSdk) {
      async function disableNavigationToTags() {
        await hideBillboard(matterportSdk);
      }
      disableNavigationToTags();
    }
  }, [disableNavigationToTag])

  useEffect(() => {
    if (matterportSdk) {
      async function disableNavigationToTag() {
        await hideBillboard(matterportSdk);
      }
      disableNavigationToTag();
    }
  }, [disableNavigationToTag])

  const handleModelClick = () => {
    setOpenRewardModal(true);
  }

  const handleRewardModalClose = () => {
    setOpenRewardModal(false);
  }

  function getURLParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sId = urlParams.get('sid');
    const params = { locationId: sId, option: {} };
    return params;
  }

  async function initMattertags(theSdk: any) {
    if (tagsList) {
      await theSdk.Mattertag.add(tagsList);
    }
  }

  async function editStemVector() {
    const lenghtFactor = 0.3048;
    const mattertag = await matterportSdk.Mattertag.getData();

    mattertag.forEach(async (item: any) => {
      await matterportSdk.Mattertag.editStem(item.sid, { stemHeight: lenghtFactor });
    });
  }

  async function changeMattertagIcon(theSdk: any) {
    theSdk.Mattertag.registerIcon('vdoIcon', '../../assets/icon-video.svg');
    theSdk.Mattertag.registerIcon('productIcon', '../../assets/icon-pricetag.svg');

    const mattertag = await theSdk.Mattertag.getData();
    mattertag.forEach((item: any) => {
      if (item.mediaType === 'mattertag.media.video') {
        theSdk.Mattertag.editIcon(item.sid, 'vdoIcon');
      } else if (item.mediaType === 'mattertag.media.photo') {
        theSdk.Mattertag.editIcon(item.sid, 'productIcon');
      }
    });
  }

  async function hideFarProductTags(theSdk: any, currentPosition: any) {
    const mattertag = await theSdk.Mattertag.getData();
    if (currentPosition?.x && currentPosition?.z) {
      mattertag.forEach((item: any) => {
        let checkX = checkTagPosition(currentPosition.x, item.anchorPosition.x);
        let checkZ = checkTagPosition(currentPosition.z, item.anchorPosition.z);

        if (checkX && checkZ) {
          theSdk.Mattertag.editOpacity(item.sid, 1);
        } else {
          theSdk.Mattertag.editOpacity(item.sid, 0);
        }
      });
    }
  }

  function checkTagPosition(currentPosition: number, tagPosition: number) {
    const n = 8;
    if (tagPosition < currentPosition + n && tagPosition > currentPosition - n) {
      return true;
    } else { return false; }
  }

  async function hideBillboard(theSdk: any) {
    const mattertag = await theSdk.Mattertag.getData();
    mattertag.forEach((item: any) => {
      theSdk.Mattertag.preventAction(item.sid, {
        opening: true,
        navigating: disableNavigationToTag,
      });
    });
  }

  async function changeLocation(theSdk: any, locationId: string, options: any) {
    const arrivedLocationId = await theSdk.Sweep.moveTo(locationId, options);
    if (arrivedLocationId) {
      console.log('Arrived at sweep ' + arrivedLocationId);
    } else {
      console.log('Error Arrived at sweep ' + arrivedLocationId);
    }
  }

  async function getPosition(theSdk: any) {
    theSdk.Pointer.intersection.subscribe(function (intersection: any) {
      // console.log("hello", intersection);
    })
  }

  async function toggleDisableSweep(disable: boolean) {
    await matterportSdk.Sweep.data.subscribe({
      onAdded: async function (index: any, item: any, collection: any) {
        if (disable) {
          await matterportSdk.Sweep.disable(index);
        } else if (!disable) {
          await matterportSdk.Sweep.enable(index);
        }
      },
    });
  }

  function clickToPlaceTag() {
    // setTimeout(function () {
    if (document.activeElement === iFrameRef.current) {
      placeTag(); //function you want to call on click
      iFrameRef.current.focus();
    }
    // }, 0);
  }

  async function placeTag() {
    if (tagRef.current) {
      if (actionState === ActionState.isAdd && currentUpdatedTagRef.current && tagRef.current === currentUpdatedTagRef.current.sid) {
        setAddedTag(currentUpdatedTagRef.current);
        setOpenAddTag(true);
      } else if (actionState === ActionState.isMove) {
        const mattertags: Mattertag.MattertagData[] = await matterportSdk.Mattertag.getData();
        let tagObj: Mattertag.MattertagData = mattertags.find((x: Mattertag.MattertagData) => x.sid === tagRef.current);
        saveTagPositionChanged(tagObj);
      }
    }
    tagRef.current = null;
  }

  async function createTagHandler(mpSdk: any) {
    await toggleDisableSweep(true);
    addTag();

    // window.addEventListener('blur', clickToPlaceTag);

    function updateTagPos(newPos: any, newNorm: any = undefined, scale: any = undefined) {
      if (!newPos) return;
      if (!scale) scale = .33;
      if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };

      mpSdk.Mattertag.editPosition(tagRef.current, {
        anchorPosition: newPos,
        stemVector: {
          x: scale * newNorm.x,
          y: scale * newNorm.y,
          z: scale * newNorm.z,
        }
      })
        .catch((e: any) => {
          console.error(e);
          tagRef.current = null;
        });
    }

    mpSdk.Pointer.intersection.subscribe((intersectionData: any) => {
      if (tagRef.current) {
        if (intersectionData.object === 'intersectedobject.model' || intersectionData.object === 'intersectedobject.sweep') {
          updateTagPos(intersectionData.position, intersectionData.normal);
        }
      }
    });

    async function addTag() {
      if (!tagRef.current) {
        const newTagId = await mpSdk.Mattertag.add([{
          label: "426358-02",
          description: "",
          anchorPosition: { x: 0, y: 0, z: 0 },
          stemVector: { x: 0, y: 0, z: 0 },
          color: { r: 1, g: 0, b: 0 },
          media: {
            type: 'mattertag.media.photo',
            src: '',
          },
        }]);

        mpSdk.Mattertag.editIcon(newTagId[0], 'productIcon');
        mpSdk.Mattertag.preventAction(newTagId[0], {
          opening: true,
          navigating: disableNavigationToTag,
        });
        tagRef.current = newTagId[0];
      } else {
        tagRef.current = tagRef.current;
      }
    }
  } // loadedShowcaseHandler

  async function createCameraControl(theSdk: any) {
    const cameraNode = await theSdk.Scene.createNode();
    const cameraPose = await theSdk.Camera.getPose();
    let cameraInput: SceneComponent = cameraNode.addComponent(cameraInputType);
    // convert sdk pose to THREE.js objects
    cameraInput.inputs.startPose = {
      position: new Vector3(cameraPose.position.x, cameraPose.position.y, cameraPose.position.z),
      quaternion: new Quaternion().setFromEuler(new Euler(
        cameraPose.rotation.x * Math.PI / 180,
        cameraPose.rotation.y * Math.PI / 180,
        (cameraPose.rotation.z || 0) * Math.PI / 180,
        'YXZ')),
      projection: new Matrix4().fromArray(cameraPose.projection).transpose(),
    };
    const cameraControl = cameraNode.addComponent('mp.camera');
    cameraControl.bind('camera', cameraInput, 'camera');
    cameraNode.start();
  }

  async function handleTagClicked(tagSid: string) {
    setPrevPosition({ sweep: currentPoseRef.current.sweep, rotation: currentPoseRef.current.rotation })

    const mattertags: Mattertag.MattertagData[] = await matterportSdk.Mattertag.getData();
    let tagObj: Mattertag.MattertagData = mattertags.find((x: Mattertag.MattertagData) => x.sid === tagSid);
    if (tagObj && tagObj.label) {
      setTagData(tagObj);
      if (actionState === ActionState.none) {
        setOpenModal(prevState => !prevState);
      } else if (actionState === ActionState.isRemove) {
        handleRemoveTagModalOpen();
      } else if (actionState === ActionState.isEdit) {
        handleEditTagModalOpen();
      } else if (actionState === ActionState.isMove) {
        handleMoveTag(tagObj);
      }
    }
  }

  async function handleModalClose() {
    setOpenModal(false);
    if (navigationValue === "2") {
      const locationId = prevPosition.sweep
      const rotation = prevPosition.rotation;
      const transition = matterportSdk.Sweep.Transition.FLY;
      await changeLocation(matterportSdk, locationId, {
        rotation: rotation,
        transition: transition,
      });
    }
  }

  useEffect(() => {
    if (!openModal) {
      setTagData(null);
    }
  }, [openModal])

  /// Add Tag
  const handleToggleAddButton = async () => {
    setActionState((prvState) => prvState === ActionState.isAdd ? ActionState.none : ActionState.isAdd);
    await createTagHandler(matterportSdk);
  }

  const handleAddTagClose = async () => {
    let sid = addedTag && addedTag.sid ? addedTag.sid : tagRef.current;
    await matterportSdk.Mattertag.remove(sid);
    tagRef.current = null;
    setOpenAddTag(false);
    setAddedTag('');
    await toggleDisableSweep(false);
    if (actionState !== ActionState.none) { setActionState(ActionState.none) }
  }

  const handleAddTagSave = async (sid: string, properties: any) => {
    await matterportSdk.Mattertag.editBillboard(sid, properties);
    setOpenAddTag(false);
    setAddedTag('');
    await toggleDisableSweep(false);
    if (actionState !== ActionState.none) { setActionState(ActionState.none) }
  }

  /// Remove Tag
  const handleRemoveTagModalClose = () => {
    setOpenRemoveTag(false);
    setTagData(null);
  }

  const handleRemoveTagModalOpen = () => {
    setOpenRemoveTag(true);
  }

  const handleIsRemoveTagToggle = () => {
    setActionState((prvState) => prvState === ActionState.isRemove ? ActionState.none : ActionState.isRemove);
  }

  const handleSaveRemoveClick = async (sid: string) => {
    await matterportSdk.Mattertag.remove(sid);
    handleRemoveTagModalClose();
  }

  /// Edit Tag Label
  const handleIsEditTagToggle = () => {
    setActionState((prvState) => prvState === ActionState.isEdit ? ActionState.none : ActionState.isEdit);
  }

  const handleEditTagModalOpen = async () => {
    setOpenEditTag(true);
  }

  const handleEditTagModalClose = () => {
    setOpenEditTag(false);
    setTagData(null);
  }

  const handleSaveEditClick = async (sid: string, properties: any) => {
    await matterportSdk.Mattertag.editBillboard(sid, properties);
    handleEditTagModalClose();
  }

  /// Move Tag Position
  const handleIsMoveTagToggle = () => {
    setActionState((prvState) => prvState === ActionState.isMove ? ActionState.none : ActionState.isMove);
  }

  const handleMoveTag = async (tagObj: Mattertag.MattertagData) => {
    tagRef.current = tagObj.sid;
    tagDataRef.current = tagObj;
    iFrameRef.current.blur();
    await createTagHandler(matterportSdk);
  }

  const objToString = (obj: any) => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');

  const saveTagPositionChanged = async (newTag: Mattertag.MattertagData) => {
    const tagPosition = objToString(tagDataRef.current.anchorPosition);
    const newTagPosition = objToString(newTag.anchorPosition);
    const newTagStemVector = objToString(newTag.stemVector);

    try {
      const apiResult = await apiService.updateTagPosition(newTag.label, tagPosition, newTagPosition, newTagStemVector);
      console.log('====================================');
      apiResult && console.log("apiResult >>>", apiResult);
      console.log('====================================');
      if (apiResult) {
        tagDataRef.current = null;
        setTimeout(async () => await toggleDisableSweep(false), 300);
      }
    }
    catch (error) {
      console.log('error !!!', error.response);
      if (error.response && error.response.data.errors) {
        console.log("save tag error", Object.values(error.response.data.errors)[0]);
      }
    }
  }

  /// Subscribes/Unsubscribes from Click Event.
  useEffect(() => {
    if (matterportSdk) {
      matterportSdk.on(matterportSdk.Mattertag.Event.CLICK, handleTagClicked);
      if (actionState === ActionState.isMove || actionState === ActionState.isAdd) {
        window.addEventListener('blur', clickToPlaceTag);
      }
    }
    return () => {
      if (matterportSdk) {
        matterportSdk.off(matterportSdk.Mattertag.Event.CLICK, handleTagClicked);
        if (actionState === ActionState.isMove || actionState === ActionState.isAdd) {
          console.log("removeEventListener >>", actionState);
          window.removeEventListener('blur', clickToPlaceTag);
        }
      }

    };
  }, [actionState])

  return (
    <div className='main' id='parent-main' style={{ position: 'relative', }}>
      <LogoHeader />
      <Frame src={src} ref={iFrameRef} />
      <ModalContainer open={openModal} close={handleModalClose} tagData={tagData} />
      <div style={{ display: 'none' }}>
        <BackgroundMedia />
        <OptionSetting navigationValue={navigationValue} handleNavigationValueChange={setNavigationValue} />
      </div>

      <FidelityIconLink />
      {/* <NavigationMenu changeLocation={changeLocation} theSdk={matterportSdk} /> */}

      <RewardModal open={openRewardModal} onClose={handleRewardModalClose} />
      <div style={{ position: 'absolute', bottom: 20, right: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 64 }}>
        <AddTagModal actionState={actionState} open={openAddTag} onClose={handleAddTagClose} tagData={addedTag} onSave={handleAddTagSave} handleToggleAddButton={handleToggleAddButton} />
        <RemoveTag open={openRemoveTag} onClose={handleRemoveTagModalClose} handleIsRemoveTagToggle={handleIsRemoveTagToggle} actionState={actionState} onSave={handleSaveRemoveClick} tagData={tagData} />
        {/* <div style={{ display: 'none' }}> */}
        <EditTagLabel open={openEditTag} onClose={handleEditTagModalClose} handleIsEditTagToggle={handleIsEditTagToggle} actionState={actionState} onSave={handleSaveEditClick} tagData={tagData} />
        {/* </div> */}
        <MoveTag handleIsMoveTagToggle={handleIsMoveTagToggle} actionState={actionState} />
      </div>
    </div>
  );
}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): { [key: string]: string } => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  // url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
