import { Canvas, useFrame } from '@react-three/fiber';
import { ContactShadows, Environment, Html, PresentationControls, useProgress } from '@react-three/drei';
import React, { Suspense, useRef, } from 'react';
import BorderLinearProgress from '../styled-components/BorderLinearProgress';
import { GiftBox } from '../gltf-components/GiftBox';

function Rotate({ children }: any) {
    const groupRef = useRef<THREE.Group>();
    useFrame(({ clock }) => {
        groupRef.current.rotation.y = Math.sin(clock.getElapsedTime() / 4) * Math.PI;
    })
    return (
        <group position={[0, 0, 0]} ref={groupRef}>
            {children}
        </group>
    )
}

function Loader() {
    const { progress } = useProgress()
    return <Html center>
        <BorderLinearProgress variant="determinate" value={progress} />
    </Html>
}

function ProductCanvas(props: any) {
    return (
        <Canvas shadows >
            <ambientLight intensity={0.1} />
            <spotLight position={[10, 10, 10]} angle={0.35} penumbra={1} shadow-mapSize={[512, 512]} castShadow />
            <Suspense fallback={<Loader />}>
                <PresentationControls global speed={3} zoom={1} >
                    <Rotate>
                        <GiftBox scale={0.75} />
                    </Rotate>
                </PresentationControls>
                <ContactShadows position={[0, -2, 0]} opacity={0.75} blur={2.6} far={100} />
                <Environment preset="city" />
            </Suspense>
        </Canvas>
    );
}

export default ProductCanvas;